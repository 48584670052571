.login-wrapper, .sign-up-wrapper, .forgot-wrapper, .set-password-wrapper {
    color: #121416;
    position: relative;
    background-color: #fcf5de;
    min-width: 1080px;

    *, ::after, ::before {
        box-sizing: border-box;
    }

    &._register {
        text-align: center;
        display: flex;
        height: 100vh;

        h4, p {
            line-height: 1.5;
            letter-spacing: .4px;
        }

        .item {
            margin: auto;

            img {
                max-width: 100%;
            }
        }
    }

    .login-icon {
        position: absolute;
        left: 50%;
        top: 12%;
        height: 291px;
        width: 321px;
    }

    .signup-icon {
        position: absolute;
        left: 55%;
        top: 24%;
        height: 133px;
        width: 147px;
    }

    .svg-icon {
        margin: auto;
        height: 100px;
        width: 100px;

    }

    .msg {
        font-size: 22px;
        font-weight: 500;
        margin: 30px 0 10px;
    }

    .btn-google {
        margin-top: 20px;
    }

    .login-btn {
        width: 100%;
    }

    .emoji {
        margin-left: 10px;
        background-image: url('https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png');
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        background-size: 6100% 6100%;
        background-position: 20% 63.3333%;
    }

    .content {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wrap-item {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    .logo {
        position: absolute;
        left: 20px;
        top: 20px;

        .svg-icon {
            width: 250px;
            height: 47px;
        }
    }

    h2 {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 26px;
    }

    .flex-row {
        display: flex;

        .form-group:first-child {
            margin-right: 15px;
        }
    }

    .divider {
        color: #9e9e9e;
        display: flex;
        align-items: center;
        --divider-gap: 10px;
        margin: 20px 0;

        &::before,
        &::after {
            content: '';
            height: 1px;
            background-color: #9e9e9e;
            flex-grow: 1;
        }

        &::before {
            margin-right: var(--divider-gap);
        }

        &::after {
            margin-left: var(--divider-gap);
        }
    }

    .action-sign {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 12px;

        a {
            font-weight: 500;
        }
    }

    .form-group {
        .svg-icons {
            top: 32px;
            padding: 0;
            left: 10px
        }

        .md-input {
            padding: 0 10px 0 40px;
        }
    }

    .eye-icon, .checkmark-circle {
        height: 20px;
        width: 20px;
    }

    .svg-icon-wrap {
        cursor: pointer;
        position: absolute;
        left: 12px;
        top: 11px;
    }

    .validation-container {
        .svg-icon {
            margin-right: 7px;
        }

        .item-validate {
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            &.valid {
                color: #54a702;

                .svg-icon {
                    fill: #54a702;
                }
            }
        }
    }

    .item {
        width: 400px;
        z-index: 1;

        p {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }

    .person-icon, .email-icon {
        height: 20px;
        width: 20px;
    }
}

.sign-up-wrapper .content {
    .action-sign {
        display: block;
    }
}
