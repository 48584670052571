.paper-switch {
    margin: 0;

    .md-bar {
        width: 38px;
        height: 24px;
        border-radius: 12px;
        top: 0;
        left: 0;
    }

    &.md-checked:not([disabled]) {
        .md-bar {
            background-color: $Primary2ButtonColor;
        }

        &.md-focused {
            .md-thumb::before {
                background-color: transparent;
            }
        }
    }

    .md-ink-ripple,
    .md-thumb::before {
        color: transparent !important;
        background-color: transparent !important;
    }

    .md-thumb {
        left: 1px;
        background-color: $InputBackgroundColor !important;
    }

    .md-thumb-container {
        z-index: 0;
    }
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="phone"],
input[type="search"],
textarea,
.pell-content {
    background-color: $InputBackgroundColor;
    border: solid 2px $InputBorderColor !important;
    border-radius: 5px !important;
    box-sizing: border-box;
    font-size: 13px;
    outline: none;
    padding: 5px 10px;

    &:focus {
        border-color: $AdminMenuBlueColor !important;
    }
}

.ember-power-select-trigger {
    background-color: #fff;
    border: 2px solid $InputBorderColor;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 10px;

    &:focus, &.ember-power-select-trigger--active {
        border-color: $AdminMenuBlueColor;
    }

    &.ember-power-select-multiple-trigger {
        padding: 0 10px 0 4px;
    }

    &[aria-disabled=true] {
        background-color: #eee;
        pointer-events: none;

        .ember-power-select-status-icon {
            border-top-color: #c4c4c4;
        }
    }

    &:after {
        display: none !important;
    }

    input {
        border: none !important;
        padding: 0 !important;
    }

    img {
        margin-right: 7px;
    }

    .ember-power-select-status-icon {
        width: 0;
        height: 0;
        margin-left: 10px;
        border-style: solid;
        border-width: 7px 4px 0 4px;
        border-color: $TextGreyColor transparent transparent transparent;
    }

    .ember-power-select-selected-item {
        align-items: center;
        display: flex;
        color: #121416;
    }

    .ember-power-select-multiple-options {
        display: flex;
        flex-wrap: wrap;
        margin: 4px 0 0;

        .ember-power-select-multiple-option {
            background: #ddebff;
            border-radius: 4px;
            color: #2b78e4;
            display: inline-flex;
            align-items: center;
            margin: 0 4px 4px 0;
            padding: 4px;

            .ember-power-select-multiple-remove-btn {
                text-align: center;
                width: 25px;
            }
        }
    }
}

.ember-power-select-dropdown {
    background-color: #fff;
    border: 2px solid $InputBorderColor;
    border-radius: 4px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .04);
    margin-top: 2px;
    overflow: hidden;
    position: absolute;
    z-index: 1000;

    &.ember-basic-dropdown-content--above {
        margin-top: -2px;
    }

    .ember-power-select-options {
        max-height: 300px;
        padding: 5px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .ember-power-select-group-name {
        color: #121416;
        font-weight: 500;
        display: block;

        & + .ember-power-select-options {
            .ember-power-select-option {
                padding-left: 10px;
            }
        }
    }

    .ember-power-select-search {
        padding: 4px;
    }

    .ember-power-select-search-input {
        width: 100%;
    }

    &.select-companies {
        min-width: 250px;
    }

    &.time-select {
        min-width: 50px;
        text-align: center;
    }

    &.select-code-phones {
        min-width: 250px;

        .ember-power-select-option {
            align-items: center;
            display: flex;
            padding-right: 40px;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
        }

        .fib {
            height: 22px;
            width: 22px;
        }

        .name {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .code {
            position: absolute;
            right: 5px;
        }
    }

    .social-icon-select {
        margin-right: 10px;
        vertical-align: middle;
        height: 18px;
        width: 18px;
    }

    .ember-power-select-option {
        border-radius: 4px;
        cursor: pointer;
        padding: 5px;

        &[aria-disabled=true] {
            pointer-events: none;
            opacity: .4;
        }

        &[aria-selected=true] {
            background: #ddebff;
            color: #2b78e4;
        }

        &:hover {
            background-color: $AdminMenuBlueColor;
            color: #fff;
        }

        img {
            margin-right: 7px;
            vertical-align: middle;
        }

        .bold {
            font-weight: 700;
        }

        .status {
            color: green;
            text-transform: capitalize;

            &.internal {
                color: #2b78e4;
            }

            &.draft {
                color: orange;
            }
        }
    }
}

md-input-container {
    padding: 0;
    margin: 0 !important;
    width: 100%;

    &.md-input-focused, .md-input-invalid {
        .md-input {
            padding-bottom: 10px;
        }
    }

    .md-input {
        color: #989a9f;
        min-height: 40px;
        padding: 10px;
        width: 100%;

        &.ng-invalid.ng-dirty {
            //padding-bottom: 10px;
        }
    }

    textarea.md-input {
        line-height: 17px;
        min-height: 70px;
        padding: 5px 10px;
        overflow: auto;
    }
}

.form-group {
    margin-bottom: 16px;
    position: relative;

    &.disabled {
        pointer-events: none;

        .copy-btn {
            background-color: #dadada;
            color: #adadad;
        }
    }

    .field-phone {
        display: flex;
        align-items: center;

        .ember-power-select-trigger {
            margin-right: 10px;
            min-width: 110px;
        }
    }

    .sub-form-group {
        margin-bottom: 8px;
    }

    label {
        color: #121416 !important;
        font-weight: 400 !important;
    }

    &.in-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            flex: 1;
            line-height: 34px !important;
        }
    }

    .md-label,
    label,
    .label,
    span {
        .svg-icon {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
    }

    .label {
        color: $TitleColor;
        font-weight: 500;
        margin-bottom: 5px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .title-icon {
        height: 20px;
        width: 20px;
        margin-right: 5px;
    }

    .extra-text {
        font-size: 12px;
        margin-top: 4px;
    }

    .info-icon {
        width: 24px !important;
        height: 24px !important;
        margin-right: 12px;
    }
}

.form-group {
    md-radio-group {
        display: flex;
        padding: 10px;

        md-radio-button {
            margin: 0 20px 0 0;
        }
    }
}

md-sidenav {
    md-toolbar {
        background-color: $InputBackgroundColor !important;
        text-transform: uppercase;
        color: #121416 !important;
    }
}

.md-sidenav-right {
    .md-toolbar-tools {
        padding: 34px 45px 10px 45px;
    }
}

.time-interval-select {
    display: flex;
    align-items: center;

    .ember-basic-dropdown-trigger {
        padding: 0 !important;
        justify-content: center;
        width: 30px;

        .ember-power-select-status-icon {
            display: none;
        }
    }

    span {
        margin: 0 8px 0 4px;
    }
}

md-menu-content {
    padding: 0;

    md-menu-item {
        min-height: 40px;
        height: 40px;
        padding: 5px 10px;

        .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        .user-status {
            bottom: 0;
            display: inline-block;
            margin-right: 5px;
            position: relative;
        }

        a, .md-button {
            box-shadow: none;
            font-size: 14px;
            color: #989a9f;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            letter-spacing: 0;
            min-width: 0;
            justify-content: initial;

            &:hover {
                background-color: transparent !important;
                color: #2b78e4;

                .svg-icon {
                    fill: #2b78e4;
                }
            }
        }

        .md-ripple-container {
            transition: none;
        }
    }
}

table {
    border-spacing: 0;

    &.md-table {
        .md-cell, .md-column {
            width: auto !important;
            max-width: unset !important;
            padding: 0 12px;

            &.checkbox,
            &.actions {
                width: 2em !important;
            }
        }
    }

    thead {
        th {
            text-transform: uppercase;
            color: $TextLightGreyColor;
            font-size: 12px;
            border-bottom: solid 1px #F5F5F5;
        }
    }

    tbody {
        tr {
            td {
                //border-bottom: solid 1px #F5F5F5;

                &:first-child {
                    border-left: solid 1px #F5F5F5;
                }

                &:last-child {
                    border-right: solid 1px #F5F5F5;
                }

                .paper-switch {
                    margin: 0;
                }
            }
        }
    }
}

table.md-table {
    thead {
        th {
            font-size: 14px !important;
        }
    }

    tbody {
        tr {
            td {
                font-size: 14px !important;

                .md-button {
                    font-size: 14px !important;
                }
            }
        }
    }
}

.admin-table {
    &.loading {
        opacity: .2;
    }

    &._fixed {
        table {
            table-layout: fixed;

            .md-table th.md-column:last-child {
                padding-right: 40px;
            }

            //th:last-child {
            //    width: 40px !important;
            //}

            td {
                overflow: hidden;
                text-overflow: ellipsis;

                &:last-child {
                    width: 40px !important;
                    padding-right: 0;
                }
            }
        }
    }

    table {
        thead {
            th {
                text-transform: none !important;
                color: $TitleColor !important;
                font-size: 14px !important;
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #eee;
                }

                td {
                    color: #989a9f !important;
                    min-width: 40px;

                    .link {
                        color: $TitleColor !important;
                        white-space: nowrap;
                        font-weight: 500;
                        position: relative;

                        &:hover {
                            color: #1844ef !important;
                        }
                    }

                    .title {
                        color: $TitleColor !important;
                    }

                    .svg-icon {
                        fill: #989a9f !important;
                    }

                    .md-avatar {
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .group-table {
        tr td:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px !important;
        }
    }
}

span {
    &.system-tag {
        font-weight: bold;
        background-color: #d4d4d4;
        display: inline-block;
        border-radius: 4px;
        padding: 5px 15px;
        margin-left: 10px;
        color: #4caf50;
    }
}

svg {
    display: inline-block;
    fill: $LabelColor;
    min-width: 0;
    min-height: 0;
}

.user-selector,
.department-selector,
.status-selector,
.priority-selector,
.id-selector,
.source-selector {
    margin: 0 5px 0 0;

    md-checkbox {
        float: left;
        clear: both;
    }
}

.priority-selector,
.source-selector,
span.NA,
span.PHONE,
span.EMAIL,
span.LIVECHAT,
span.WECHAT,
span.TELEGRAM,
span.LINE,
span.TWITTER,
span.WHATSAPP,
span.FACEBOOK,
span.INSTAGRAM,
span.URGENT,
span.HIGH,
span.MEDIUM,
span.LOW {
    svg {
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
    }
}

.source-selector.NA,
span.NA {
    svg {
        display: none;
    }
}


$colorTaskPriorityUrgent: #CF2A27;
$colorTaskPriorityHigh: #FF6F1D;
$colorTaskPriorityMedium: #FBBC05;
$colorTaskPriorityLow: #34A853;

span.URGENT {
    color: $colorTaskPriorityUrgent;

    svg {
        fill: $colorTaskPriorityUrgent;
        vertical-align: text-bottom;
    }
}

span.HIGH {
    color: $colorTaskPriorityHigh;

    svg {
        fill: $colorTaskPriorityHigh;
        vertical-align: text-bottom;
    }
}

span.MEDIUM {
    color: $colorTaskPriorityMedium;

    svg {
        fill: $colorTaskPriorityMedium;
        vertical-align: text-bottom;
    }
}

span.LOW {
    color: $colorTaskPriorityLow;

    svg {
        fill: $colorTaskPriorityLow;
        vertical-align: text-bottom;
    }
}

$colorTaskStatusOpen: #6163C2;
$colorTaskStatusInProgress: #FF9900;
$colorTaskStatusResolved: #53AE34;
$colorTaskStatusClosed: #68758F;
$colorTaskStatusReopened: #F6B26B;
$colorTaskStatusArchived: #a5a5a5;
$colorTaskStatusDeleted: #a5a5a5;

span.OPEN {
    color: $colorTaskStatusOpen;
}

span.IN_PROGRESS {
    color: $colorTaskStatusInProgress;
}

span.RESOLVED {
    color: $colorTaskStatusResolved;
}

span.CLOSED {
    color: $colorTaskStatusClosed;
}

span.RE_OPENED {
    color: $colorTaskStatusReopened;
}

span.ARCHIVED {
    color: $colorTaskStatusArchived;
}

span.DELETED {
    color: $colorTaskStatusDeleted;
}

.flatpickr-calendar {
    .flatpickr-current-month .flatpickr-monthDropdown-months {
        -webkit-appearance: none !important;
    }
}
