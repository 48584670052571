md-dialog {
    md-toolbar {
        background-color: $Primary2ButtonColor !important;

        button {
            display: none !important;
        }
    }

    .md-dialog-content {
        color: $TextLightGreyColor;

        .content-title {
            color: $TitleColor;
            font-weight: 500;
            margin-bottom: 14px;
        }

        .ember-power-select-trigger {
            margin-bottom: 10px;
        }

        .content-body {
            display: flex;
            flex-direction: row;
            margin-bottom: 12px;
            line-height: 20px;
        }

        p, b {
            display: inline-flex;
            line-height: 20px;
            margin-bottom: 12px;
        }

        b {
            //color: $TextLightGreyColor;
            font-weight: 400;
            border: solid 2px lightgreen;
            color: red;
        }

        svg {
            width: 20px;
            min-width: 20px;
            height: 20px;
            min-height: 20px;
            margin-right: 8px;
        }
    }
}

.warning-dialog {
    min-width: 532px;
    max-width: 532px;

    md-toolbar {
        //height: 80px;
        //min-height: 80px;
        //max-height: 80px;
        padding: 10px 20px;

        .md-toolbar-tools {
            padding: 0;

            h2 {
                margin-left: 25px;
            }
        }

        .svg-icon {
            //margin-right: 20px;
            width: 24px;
            height: 24px;
            fill: white;
            position: absolute;
            left: 10px;
        }
    }

    .md-dialog-content {
        padding: 27px 45px 0 45px;

        .svg-icon {
            &.blue {
                fill: $AdminMenuBlueColor;
            }
        }
    }

    .content-body {
        &.displayBlock {
            display: block;
        }
    }

    md-dialog-actions {
        display: flex;
        justify-content: space-between;
        padding: 35px 45px;

        .md-button {
            margin: 0;
        }

        .primary-button {
            background-color: #2b78e4;
            border-color: #2b78e4;
        }
    }
}
