$color-step-error: #f44336;

$swift-ease-out-duration: 0.4s;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function;

$swift-ease-in-duration: 0.3s;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function;

$swift-ease-in-out-duration: 0.5s;
$swift-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function;

$swift-linear-duration: 0.08s;
$swift-linear-timing-function: linear;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function;

$paper-stepper-primary: if(function-exists(paper-color), paper-color($primary), color($primary));

md-stepper {
  display: block;
}

md-steppers-header, md-steppers-mobile-header, md-step-actions {
  flex: 0 0 auto;
  box-sizing: border-box;
}

.md-steppers-header-region {
  z-index: 1;
}

@media (max-width: 599px) {
  md-stepper {
    display: flex;
    flex-direction: row;
    > div {
      min-width: 0px;
      flex:1;
    }
  }
  .md-steppers-content {
    min-height: 0px;
    display: flex;
    flex-direction: column;
  }
  md-step.md-active {
    min-height: 0px;
    flex:1;
    display: flex;
    flex-direction: column;
    .md-stepper {
      display: flex;
      flex-direction: column;
      flex:1;
      min-height: 0px;
    }
  }

  md-steppers-scope {
    flex:1;
  }

  md-step-body {
    flex:1;
  }
}

.md-steppers {
  display: flex;
  flex-flow: column;

  &:not(.md-steppers-linear) {
    .md-stepper-indicator {
      &:not(.md-active):not(.md-completed):hover,
      &.md-editable.md-completed:hover {
        cursor: pointer;

        &,
        .md-stepper-indicator-wrapper {
          background-color: #F6F6F6;
        }
      }
    }
  }
}

.md-steppers-header {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 0;
  align-items: flex-start;
}

.md-steppers-horizontal {
  .md-stepper-indicator {
    min-height: 72px;

    &:first-child {
      .md-stepper-indicator-wrapper {
        padding-left: 16px;
      }
    }

    &:after {
      width: 999em;
      height: 1px;
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      background-color: #E8E8E8;
      content: " ";
    }
  }
}

.md-stepper-indicator {
  margin:0;
  padding: 0 16px;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  align-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  cursor: default;
  background: none;
  border: none;
  transition: $swift-ease-out;

  &:focus {
    outline: none;
  }

  &.md-completed,
  &.md-active {
    .md-stepper-number {
      background-color: $paper-stepper-primary;
      @if function-exists(paper-var) {
        background-color: paper-var(--primary-500);
      }
    }

    .md-stepper-title {
      color: rgba(0, 0, 0, .87);
    }
  }

  &.md-active,
  &.md-editable {
    .md-stepper-title {
      font-weight: 500;
    }
  }

  &.md-error {
    .md-stepper-title {
      color: $color-step-error;
    }
  }

  &:first-child {
    padding-left: 0;

    // .md-stepper-indicator-wrapper {
    //   padding-left: 16px;
    // }
  }

  &:last-child {
    padding-right: 0;
    justify-content: flex-end;

    .md-stepper-indicator-wrapper {
      padding-right: 24px;
    }
  }



  .md-ripple-container {
    z-index: 3;
  }
}

.md-stepper-indicator-wrapper {
  padding: 0 8px 0 0;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  align-content: center;
  background-color: #fff;
  transition: $swift-ease-out;
}

.md-stepper-number {
  $size: 24px;

  width: $size;
  height: $size;
  margin: 0 8px;
  border-radius: $size;
  background-color: #BDBDBD;
  transition: $swift-ease-out;
  color: #fff;
  font-size: 12px;
  line-height: $size;
  text-align: center;

  &.ng-hide {
    transition: none;
  }
}

.md-error {
  .md-stepper-error-indicator {
    margin: 0 8px;
    background-color: #fff;

    md-icon {
      color: $color-step-error;
    }
  }

  .md-stepper-error-message {
    color: $color-step-error;
    font-weight: 400;
  }
}

md-icon.md-default-theme.md-stepper-icon {
  $size: 18px;

  width: $size;
  height: $size;
  position: relative;
  top: -1px;
  color: #fff;
  font-size: $size;
  font-weight: 700;

  &.md-stepper-icon-edit {
    $size: 14px;

    width: $size;
    height: $size;
    font-size: $size;
    font-weight: 400;
  }
}

.md-stepper-title {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  transition: $swift-ease-out;
  font-size: 14px;
  line-height: 24px;

  text-align: left;
  white-space: nowrap;

  small:not(.md-stepper-error-message) {
    color: rgba(0, 0, 0, .54);
  }

  small {
    transition: $swift-ease-out;
    font-size: 12px;
    line-height: 1em;
  }
}

.md-steppers-content {
  position: relative;
  //overflow: hidden;
  flex: 1;
  order: 2;
}

// md-stepper {
//   display: block;
// }

.md-stepper {
  box-sizing: border-box;
  //position: absolute;
  // top: 0;
  // right: 0;
  // left: 0;
  // pointer-events: none;
  // opacity: 0;
  //transition: .4s cubic-bezier(.35, 0, .25, 1);

  &.md-active {
    position: relative;
    pointer-events: auto;
    // opacity: 1;
  }
}

.md-steppers-actions {
  display: flex;
}


// --------------------------------------------------
// LINEAR

.md-steppers-linear {
  .md-stepper-indicator {
    cursor: default;

    &.md-editable.md-completed:hover {
      cursor: pointer;

      &,
      .md-stepper-indicator-wrapper {
        background-color: #F6F6F6;
      }

      .md-stepper-number {
        &:before,
        &:after {
          background-color: #F6F6F6;
        }
      }
    }
  }

  .md-stepper-title {
    color: rgba(0, 0, 0, .26);

    small:not(.md-stepper-error-message) {
      color: rgba(0, 0, 0, .26);
    }
  }
}

// --------------------------------------------------
// ALTERNATIVE

.md-steppers-alternative {
  &:not(.md-steppers-linear) {
    .md-stepper-indicator:not(.md-active):not(.md-completed):hover {
      .md-stepper-indicator-wrapper {
        background: none;
      }

      .md-stepper-number {
        &:before,
        &:after {
          background-color: #F6F6F6;
        }
      }
    }
  }

  .md-steppers-horizontal {
    .md-stepper-indicator {
      min-height: 104px;
      padding: 24px 16px;
      flex: 1;
      justify-content: center;

      .md-stepper-indicator-wrapper {
        padding: 0;
      }

      &:first-child {
        padding-left: 24px;

        &:after {
          left: 50%;
        }
      }

      &:last-child {
        padding-right: 24px;

        &:after {
          right: 50%;
          left: auto;
        }
      }

      &:after {
        margin-top: 0;
        top: 36px;
      }
    }

    .md-stepper-indicator-wrapper {
      padding: 0 16px;
      min-height: 50px;
      flex-flow: column nowrap;
      background: none;
    }

    .md-stepper-number,
    .md-stepper-error-indicator {
      position: relative;

      &:before,
      &:after {
        width: 8px;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: #fff;
        transition: $swift-ease-out;
        content: " ";
      }

      &:before {
        left: -8px;
      }

      &:after {
        right: -8px;
      }
    }

    .md-stepper-title {
      margin-top: 16px;
      line-height: 18px;
      text-align: center;
      small {
        text-align: center;
      }
    }
  }
}


// ------------------------------------------------
// VERTICAL


.md-steppers-vertical {
  padding: 16px;

  .md-stepper-number {
    margin: 0 8px 0 0;
  }

  .md-stepper {
    padding: 0;
  }

  .md-steppers-scope {
    //display: block;
    margin-left: 20px;
    border-left: 1px solid #E8E8E8;
    padding-left: 20px;
    min-height: 25px;
  }

  md-step:last-child {
    .md-stepper .md-steppers-scope {
      border-left: 1px solid transparent;
    }
    .md-stepper:not(.md-active) {
      .md-steppers-scope {
        display: none;
      }
    }

    md-step-actions {
      padding-bottom: 0;
    }
  }

  .md-stepper-indicator {
    padding: 8px;
  }

  md-step-actions {
    padding-bottom: 40px;

    & > .md-button:first-child {
      margin-left: 0;
    }

    & > .md-button:last-child {
      margin-right: 0;
    }
  }

  md-step-body {
    padding-bottom: 16px;
    overflow: visible; // reset to overflow visible
    & > *:last-child {
      margin-bottom: 0;
    }
  }

}


// ------------------------------------------------
// MOBILE-STEP-TEXT
.md-steppers-mobile-header, .md-stepper-feedback-message {
  display: none;
}


@media (max-width: 599px) {

.md-steppers-mobile-step-text:not(.md-steppers-vertical) {
    .md-stepper {
      padding: 0;
    }
    // md-step-body {
    //   padding: 24px;
    // }


    .md-steppers-mobile-header {
      display: block;
    }
    .md-stepper-feedback-message{
        display: flex;
    }

    .md-steppers-header.md-steppers-horizontal {
      display: none;
    }

    md-step-actions {
      background: #f6f6f6 !important;
      color: #202020 !important;
      flex-direction:row;
      box-sizing:border-box;
      display:flex;
      align-content:stretch;
      align-items:stretch;
      justify-content:space-between;
      box-shadow:rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
    }
  }
}

// ------------------------------------------------
// Feedback

.md-steppers-header-region {
  position: relative;
}

.md-stepper-feedback-message {
  position:absolute;
  top:0;
  left:0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  align-items: center;
//   display: flex;
  padding-left: 10px;
  z-index: 2;
  box-sizing: border-box;
}

.md-steppers {
  position: relative;
}
md-step-body{
  position: relative;
//   padding: 24px;
  overflow: auto;
  flex-direction: column;
}
.md-step-body-loading {
  display: none;
}

.md-steppers-has-feedback {
  .md-steppers-overlay {
    position:absolute;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
  .md-step-body-overlay {
    position:absolute;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    z-index: 90;
    opacity: 0.75;
    background-color: white;
  }
  .md-step-body-loading {
    display: flex;
    position:absolute;
    top:0;
    left:0;
    bottom: 0;
    width: 100%;
    z-index: 91;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    align-content: center;
    max-width: 100%;
    justify-content: center;
  }
}
