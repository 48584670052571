.md-stepper-indicator {
    padding: 10px 10px !important;
    min-height: auto !important;

    &:last-child {
        justify-content: flex-start;
    }
}

#import-upload-preview {
    background-color: #fff;
    border: 1px #cacaca dashed;
    padding: 20px;
    border-radius: 5px;
    margin-top: 5px;

    .dz-file-preview {
        display: none !important;
    }

    .dz-details {
        width: auto;
        margin: 20px auto;
        text-align: center;

        .dz-filename {
            font-size: 14px;
            color: #3f69cc;
        }

        .dz-size {
            font-size: 10px;
        }
    }
}

.dropzone .dz-preview .dz-image img {
    height: 100%;
}

md-step-body {
    padding: 0 !important;
}

.md-steppers-horizontal {
    box-shadow: none;
}

.md-stepper-number {
    margin: 13px 8px 0;
}

.md-stepper-number:before {
    background: transparent !important;
}

.md-stepper-number:after {
    background: transparent !important;
}

.import-file-upload-drag-drop {
    height: 180px !important;
}

.import-page {
    .content {
        .md-steppers-header {
            box-shadow: none;
        }

        .md-steppers-header-region {
            z-index: 0;
        }

        .module-selectors {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .module-select {
                max-width: 150px;
                border: solid 2px $TextLightGreyColor;
                background-color: #EEECEC;
                border-radius: 15px;
                padding: 10px;
                margin: 0 15px;
                cursor: pointer;

                &.active {
                    border-color: #B0DCF7;
                    background-color: #EFFAFF;
                }

                .title {
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 15px
                }

                .description {
                    text-align: center;
                    font-size: 12px;
                }
            }
        }

        .history-table {
            flex: 20;
            overflow-y: auto;

            th,
            td {
                width: auto;
                text-overflow: ellipsis;
                overflow: hidden;

                &:first-child {
                    max-width: 280px !important;
                }
            }
        }

        .step-complete {
            text-align: center;

            h3 {
                text-transform: uppercase;
            }
        }

        .step-content {
            width: 70%;
            margin: 0 auto;
            overflow-y: auto;

            .form-group {
                width: 50%;
            }

            .file-zone {
                text-align: center;

                h3 {
                    color: #121416;
                }

                .svg-icon {
                    fill: #017872;
                    height: 50px;
                    width: 50px;

                    &.cloud-upload {
                        fill: #2B78E4;
                    }
                }

                .dropzone-previews {
                    border: dashed 2px #2B78E4;
                    border-radius: 15px;
                    background-color: #EFFAFF;
                    padding: 20px;

                    md-icon {
                        color: #2B78E4;
                    }

                    .error {
                        border: solid 1px red;
                        color: red;
                    }
                }
            }

            .mapping-fields {
                border: solid 2px #EFFAFF;
                border-radius: 15px;
                padding: 20px;

                table {
                    margin-bottom: 30px;
                }

                .column-field {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        display: inline-block;

                        &.label {
                            flex: 20;
                        }
                    }

                    &.unmapped {
                        border: solid 1px red;
                        border-radius: 5px;
                        padding: 5px;
                    }
                }

                .column-label {
                    white-space: nowrap;

                    &.unmapped {
                        border: solid 1px #FF9900;
                        border-radius: 5px;
                        padding: 5px;
                    }
                }

                .error-message {
                    display: flex;
                    align-items: center;
                    border: 1px solid;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-top: 10px;

                    &.warning {
                        border-color: #FF9900;
                        background-color: rgba(255, 99, 0, 0.10);

                        .svg-icon {
                            fill: #FF9900;
                        }
                    }

                    &.error {
                        border-color: #FF0000;
                        background-color: rgba(255, 0, 0, 0.10);

                        .svg-icon {
                            fill: #FF0000;
                        }
                    }

                    .svg-icon {
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.column-field.create-new {
    color: #2B78E4;
    margin-top: 5px;
}

.column-field.ignore-column {
    color: #FF0000;
    margin-bottom: 5px;
}

span.required {
    background-color: #d4d4d4;
    color: #fff;
    display: inline-block;
    border-radius: 4px;
    padding: 5px 15px;
    margin-left: 10px;
    overflow: visible;
}

.popup.create-contact-field-popup {
    width: 30% !important;
    min-width: 30% !important;
    max-width: 30% !important;
}

.header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    white-space: nowrap;
    padding-left: 20px;
    width: fit-content;

    .item-link {
        color: #989a9f;
        font-weight: 400;
        font-size: 13px;
        display: flex;
        position: relative;
        margin-right: 20px;
        cursor: pointer;

        .svg-icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        &.active {
            color: #2b78e4;

            .svg-icon {
                fill: #2b78e4;
            }
        }
    }

    .direct-icon, .inbox-icon, .build-icon, .people-icon {
        height: 16px;
        margin-right: 8px;
        width: 16px;
    }
}
