.right-to-left {
    direction: rtl;

    .page-content {
        border-left: none;
        border-right: 1px solid rgba(218, 218, 218, 0.5);
        margin-left: 0;
    }

    .profile-block {
        .profile-wrap-avatar {
            margin: 0 50px 0 90px;
        }
    }

    .field-phone {
        .ember-power-select-trigger {
            margin-right: 0;
            margin-left: 10px;
        }

        .fib {
            margin-right: 0;
            margin-left: 5px;
        }

        .code {
            direction: ltr;
        }
    }

    .ember-power-select-trigger {
        &.ember-power-select-multiple-trigger {
            padding: 0 4px 0 10px;
        }

        img {
            margin-right: 0;
            margin-left: 7px;
        }

        .ember-power-select-status-icon {
            margin-left: 0;
            margin-right: 10px;
        }

        .ember-power-select-multiple-options {
            .ember-power-select-multiple-option {
                margin: 0 0 4px 4px;
            }
        }
    }

    .ember-power-select-dropdown {
        .ember-power-select-group-name {
            & + .ember-power-select-options {
                .ember-power-select-option {
                    padding-left: 0;
                    padding-right: 10px;
                }
            }
        }

        &.select-code-phones {
            .ember-power-select-option {
                padding-right: 0;
                padding-left: 40px;
            }

            .code {
                right: auto;
                left: 5px;
                direction: ltr;
            }
        }

        .social-icon-select {
            margin-right: auto;
            margin-left: 10px;
        }

        .ember-power-select-option {
            img {
                margin-right: auto;
                margin-left: 7px;
            }
        }
    }

    .page-wrapper {
        .title-tasks,
        .page-title {
            .primary-button,
            .primary-2-button,
            .secondary-button,
            .secondary-2-button {
                margin: 0 15px 0 0;

                .svg-icon {
                    margin-right: 0;
                    margin-left: 4px;
                }
            }
        }
    }

    .page-inner-wrapper {
        .page-title {
            margin: 20px 20px 0 18px;

            .expand-button {
                margin-right: 0 !important;
                margin-left: 11px !important;
            }

            h2 {
                margin-right: 0;
                margin-left: 15px;

                .title-icon {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }

        .title-avatar {
            margin-right: 0;
            margin-left: 15px;
        }
    }

    .admin-menu {
        border-right: none;
        border-left: 1px solid #ececec;

        .settings-menu-heading {
            margin: 5px 0 14px 6px;
        }
    }

    .administration-menu {
        .svg-icon {
            margin-right: 0;
            margin-left: 5px;
        }

        .menu-link {
            &.first, &.last {
                padding-left: 0;
            }
        }

        .cp-Panel {
            .cp-Panel-body-inner {
                padding: 0 15px 0 0;
            }
        }
    }

    .pagination {
        direction: ltr;
    }

    .admin-table {
        &._fixed {
            table {
                .md-table th.md-column:last-child {
                    padding-right: 0;
                    padding-left: 40px;
                }

                td {
                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }

        table {
            thead {
                th {
                    text-align: right;
                }
            }

            tbody {
                tr {
                    td {
                        text-align: right;

                        .md-avatar {
                            margin-right: 0;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    .column-select-trigger {
        right: auto;
        left: 0;
    }

    .column-select {
        .drag-list {
            svg {
                margin-right: 0;
                margin-left: 7px;
            }
        }
    }

    .popup {
        left: 0 !important;
        right: auto !important;

        md-toolbar {
            .md-toolbar-tools {
                .svg-icon {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }

        .title-channel {
            .svg-icon {
                margin-right: 0;
                margin-left: 12px;
            }
        }

        .link-facebook {
            margin-left: 0;
            margin-right: 20px;
        }

        .copy-btn {
            margin-left: 0;
            margin-right: 10px;
        }

        .close-button {
            right: auto;
            left: 15px;
        }

        .popup-footer {
            .primary-button,
            .secondary-button {
                margin-left: 0;
                margin-right: 10px;
            }

            .secondary-2-button {
                &._back {
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }
    }

    .avatar-block {
        margin-right: auto;
        margin-left: 20px;
    }

    .apps-popup {
        right: auto;
        left: 64px;

        &.user {
            top: 60px;
            right: auto;
            left: 20px;
        }

        &.chat {
            left: auto;
            right: 25px;
        }

        &.guest-popup {
            left: auto;
            right: 0;
        }

        &.group-chat {
            left: auto;
            right: 50px;
        }

        &.assign {
            right: auto;
            left: 10px;
        }

        &.reply {
            .popup-list-item {
                padding: 5px 0 4px 10px;
            }
        }

        .popup-list-item {
            padding: 5px 0 4px 10px;

            .avatar-block {
                margin-right: 0;
                margin-left: 7px;
            }
        }

        .md-list-item-inner {
            padding-left: 0;
            padding-right: 0;
        }

        .shortcut {
            padding-right: 0;
            padding-left: 4%;
        }
    }

    .warning-dialog {
        md-toolbar {
            .md-toolbar-tools {
                h2 {
                    margin-left: 0;
                    margin-right: 25px;
                }
            }

            .svg-icon {
                left: auto;
                right: 10px;
            }
        }
    }

    .md-dialog-container {
        .date-wrap-field {
            .calendar-icon {
                right: auto;
                left: 14px;
            }
        }
    }


    md-dialog {
        .md-dialog-content {
            svg {
                margin-right: 0;
                margin-left: 8px;
            }
        }
    }

    .dashboard-top {
        .label {
            margin-right: 0;
            margin-left: 12px;
        }

        .dates-selector-wrapper {
            margin-left: 0;
            margin-right: 30px;
        }
    }

    .dashboard-page {
        .label {
            margin-right: 0;
            margin-left: 12px;
        }

        .wrap-start-note-block {
            .info-icon {
                margin-right: 0;
                margin-left: 8px;
            }
        }

        .satisfaction-row {
            .svg-icon {
                margin-left: 0;
                margin-right: 5px;
            }
        }

        h4 {
            margin: 7px 0 10px 14px;
            //text-align: right;
        }

        .row-panels {
            > div {
                margin: 0 0 0 15px;
            }

            > div:first-child {
                margin-right: 0;
            }

            div:last-child {
                margin-left: 0;
            }
        }
    }

    .tasks-list {
        .grid-place {
            .side-panel {
                .panel-header {
                    padding: 10px 10px 10px 0;
                }

                .panel-form {
                    .form-content {
                        .svg-icon {
                            margin-right: 0;
                            margin-left: 5px;
                        }

                        .item {
                            .md-avatar {
                                margin-right: 0;
                                margin-left: 5px;
                            }

                            .svg-icon {
                                margin-left: 0;
                                margin-right: 5px;
                            }
                        }

                        .icon-date {
                            margin-right: 0;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }

}
