// custom styles for Tooltip
#paper-wormhole {
	.md-panel.md-tooltip {
		background-color: #fff;
		border-radius: 5px;
		border: 1px solid #eeecec;
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .15);
		color: #5d5e64;
		font-size: 11px;
		font-weight: 400;
		height: auto !important;
		line-height: 13px;
		margin-top: 5px !important;
		opacity: 1;
		padding: 10px 12px;
	}
}

md-sidenav {
	width: calc(100% - 100px);
	min-width: calc(100% - 100px);
	max-width: calc(100% - 100px);
}

.md-sidenav-backdrop {
	backdrop-filter: blur(0px);
	transition-duration: 200ms;
	opacity: 0;
	transition-property: backdrop-filter, opacity;
	transition-timing-function: linear;

	&.md-opaque {
		opacity: 1 !important;
		transition: 200ms opacity linear;
		backdrop-filter: blur(10px);
		background-color: transparent !important;
	}
}

.date-picker {
	border: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	padding: 5px 0;
	background: #fff;
	border-radius: 3px;
}

.date-picker-input {
	border: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	padding: 5px;
	width: 100%;
	margin-top: 10px;
	font-size: 14px;
}

.file-upload-drag-drop {
	border: 2px dashed rgba(0, 0, 0, 0.12);
	border-radius: 10px;
	min-height: auto;

	.dz-message {
		margin: 10px;
	}
}

.ember-power-select-search {
	background: #fff;
}

table.md-table {
	thead {
		.md-row {
			height: 40px !important;

			button {
				border-radius: 4px;
			}
		}
	}

	th.md-column {
		color: #90a4ae;
		font-weight: 600;
		padding: 0;

		&.md-active {
			color: #90a4ae;
		}

		md-icon {
			&.md-sort-icon {
				color: #90a4ae !important;
				opacity: 0 !important;
			}
		}
	}
}

.dz-preview {
	z-index: 1 !important;

	&:hover {
		.dz-image {
			img {
				-webkit-filter: none !important;
				filter: none !important;
			}
		}

		.dz-details {
			display: none;
		}
	}
}

.dz-image {
	border-radius: 10px !important;
	z-index: 1 !important;
}

.dz-success-mark {
	opacity: 1 !important;

	svg {
		p {
			fill: #017872;
		}
	}
}

md-toast {
	.md-toast-content {
		background-color: $AdminMenuBlueColor !important;
		color: #fff !important;
		font-weight: 500 !important;
	}

	.md-button {
		color: green !important;
		text-transform: uppercase !important;

		&:hover {
			background-color: transparent !important;
			text-decoration: underline !important;
		}
	}
}

md-tabs-wrapper {
	border: none !important;

	md-ink-bar {
		height: 3px;
		display: none;
	}
}

md-tabs {
	.md-tab {
		text-transform: capitalize;
		color: #989a9f;

		&.md-focused {
			background: none;
		}

		.md-ripple-container {
			display: none;
		}

		.svg-icon {
			margin-right: 3px;
			vertical-align: middle;
		}
	}

	.md-active {
		border-bottom: 2px solid #2b78e4;
		color: #2b78e4;
	}
}

md-checkbox {
	margin: 0;

	&.md-checked {
		.md-icon {
			background-color: #3f69cc !important;
		}
	}
}
