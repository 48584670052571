.flex {
    display: flex;
}

.flex-space-between {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.text-center {
    text-align: center;
}

.column-select-trigger {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;

    &:hover {
        background-color: #fff !important;
    }

    .svg-icon {
        width: 22px;
        height: 22px;
    }
}

.column-select {
    padding: 16px;

    h4 {
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    p {
        line-height: 20px;
    }

    .drag-list {
        margin-top: 30px;
        line-height: 24px;

        svg {
            width: 16px;
            height: 16px;
            vertical-align: text-bottom;
            margin-right: 7px;
            cursor: pointer;

            &.green {
                fill: #54A702;
                opacity: 0.5;
            }
        }

        .dragSortItem {
            display: flex;
            align-items: center;

            span {
                flex: 10;
            }

            md-icon {
                opacity: 0.5;
            }
        }
    }
}

.table {
    width: 100%;

    thead {
        tr {
            height: 50px;
        }

        th {
            padding: 15px;
            font-weight: 500;
            text-align: left;
            min-height: 50px;
        }
    }

    tbody {
        tr {
            transition: .2s;

            &:hover {
                background-color: rgba(0, 0, 0, .2) !important;
            }
        }

        td {
            padding: 10px 15px;
            min-height: 50px;
            white-space: nowrap;
            //max-width: 115px;

            .md-button {
                min-width: auto;
            }

            .md-icon-button {
                height: auto;
                width: auto;
                padding: 0;
                margin: 0;
            }
        }
    }
}

table.md-table td.md-cell {
    border-top: none;
    //word-break: break-all;
}

.text-red {
    color: #e24b5e !important;
}

.user-avatar {
    border-radius: 50%;
    object-fit: contain;
}

// Avatar
.wrapAvatar {
    background-color: #8b8b8b;
    border-radius: 50%;
    border: 2px solid #fff;
    font-weight: 700;
    text-align: center;
    height: 35px;
    width: 35px;
    position: relative;

    img {
        border-radius: 50%;
        object-fit: cover;
        max-width: 100%;
    }

    .status {
        background-color: #c3c9d9;
        border-radius: 50%;
        box-sizing: content-box;
        border: 2px solid #fff;
        position: absolute;
        height: 6px;
        width: 6px;
        bottom: -1px;
        right: -1px;
    }
}

.cp-Panel {
    position: relative;

    .liquid-container {
        //border: solid 2px greenyellow;
    }

    .cp-Panel-toggle {
        //border: solid 2px #f5f;
    }
}

.cp-Panel.common-panel {
    margin-bottom: 7px;
    border: solid 1px #f5f5f5;
    border-radius: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    &.cp-is-disabled {
        .cp-Panel-toggle {
            color: #dadada !important;
        }
    }

    .cp-Panel-toggle {
        display: flex;
        text-align: left;
        background: #f5f5f5;
        padding: 4px 12px;
        font-size: inherit;
        color: $TextGreyColor;
        font-weight: 500;
        border-radius: 5px;

        span {
            flex: 1;
            //color: #f5f;
        }

        .title-icon {
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }

        .arrow-icon {
            //fill: #f5f !important;
            height: 16px;
            width: 16px;
            transition: .2s;
            transform: rotate(90deg);
        }

        &.cp-is-open {
            border-radius: 5px 5px 0 0;

            .arrow-icon {
                transform: rotate(0deg);
            }
        }
    }

    .cp-Panel-body-inner {
        padding: 12px;

        .flatpickr-input {
            flex: 1;
            padding: 10px;
            border: solid 2px #f5f;
        }
    }

    .form-group {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
