.application-loading-wrapper {
	position: absolute;
	top: 50%;
	margin-top: -45px;
	left: 50%;
	margin-left: -25px;
	z-index: 11;

	p {
		margin-bottom: 20px;
		font-size: 16px;
		display: none;
	}

	.loader {
		> div {
			animation-fill-mode: both;
			background-color: #989a9f;
			border-radius: 100%;
			display: inline-block;
			margin: 0 3px;
			height: 15px;
			width: 15px;

			&:nth-child(1) {
				animation: scale .75s -.24s infinite cubic-bezier(.2, .68, .18, 1.08);
				margin-right: 5px;
			}

			&:nth-child(2) {
				animation: scale .75s -.12s infinite cubic-bezier(.2, .68,.18, 1.08);
				margin-right: 5px;
			}

			&:nth-child(3) {
				animation: scale .75s 0s infinite cubic-bezier(.2, .68, .18, 1.08);
			}

			@keyframes scale {
				0% {
					transform: scale(1);
					opacity: 1;
				}
				45% {
					transform: scale(0.1);
					opacity: 0.7;
				}
				80% {
					transform: scale(1);
					opacity: 1;
				}
			}
		}
	}
}

.overlay-loading {
	background-color: rgba(0, 0, 0, .3);
	z-index: 1000;
	position: absolute;
	display: flex;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	.application-loading-wrapper {
		margin: auto;
		top: auto;
		left: auto;
		position: relative;
	}

	.loader div {
		background-color: #fff !important;
	}
}
