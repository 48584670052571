.field-creation {
    .fields {
        border: solid 1px #f5f5f5;
        padding: 10px;

        .field-switch {
            margin-bottom: 10px;
        }

        .list {
            .item {
                border: solid 2px #dadada;
                border-radius: 4px;
                margin-bottom: 5px;

                &.blur {
                    > .field-header {
                        opacity: .3;
                    }

                    .field-title {
                        cursor: default !important;
                    }

                    .expand-sections,
                    .actions {
                        display: none !important;
                    }
                }

                .item {
                    margin: 5px;
                }

                .group-subfield-list {
                    .field-header {
                        .drag-indicator {
                            display: none;
                        }
                    }
                }

                .field-header {
                    align-items: center;
                    min-height: 40px;

                    .field-title {
                        cursor: pointer;
                        flex: 20;
                        padding: 5px;

                        .label {
                            font-weight: bold;
                        }

                        .system,
                        .required {
                            background-color: #d4d4d4;
                            display: inline-block;
                            border-radius: 4px;
                            padding: 5px 15px;
                            margin-left: 10px;
                        }

                        .system {
                            color: #4caf50;
                        }
                    }
                }

                .details {
                    .choices {
                        display: flex;
                        margin-top: 10px;
                        width: 80%;

                        .drag-item {
                            display: flex;
                            margin-top: 5px;

                            &._new {
                                margin-left: 22px;
                            }
                        }

                        .drag_handle {
                            line-height: 30px;
                        }

                        svg {
                            width: 22px;
                            height: 22px;
                            vertical-align: bottom;
                        }
                    }

                    .field-form {
                        background-color: #f9f9f9;
                        padding: 5px;

                        .field-form-item {
                            display: flex;
                            margin-top: 10px;
                            width: 80%;
                        }

                        input, textarea {
                            background-color: #fff;
                            border: solid 1px #d4d4d4;
                        }
                    }

                    .edit-actions {
                        text-align: right;
                        background-color: #fff;

                        .primary-button,
                        .secondary-2-button {
                            margin: 18px 10px 18px 0;
                        }
                    }
                }

                .sections {
                    border-top: solid 2px #d4d4d4;
                    background-color: #f9f9f9;

                    .expand-sections {
                        cursor: pointer;
                        padding: 10px;
                    }

                    .section {
                        border: solid 1px #b3bec6;
                        background-color: #fff;
                        margin: 5px;

                        .empty-list {
                            padding: 10px;
                        }

                        .details {
                            .section-title {
                                padding: 10px;
                            }

                            .section-form {
                                background-color: #f9f9f9;
                                padding: 5px;

                                .section-form-item {
                                    padding: 5px;
                                    width: 70%;
                                }

                                .selected-list {
                                    margin-top: 5px;
                                    padding: 2px;

                                    .item {
                                        align-items: center;
                                        cursor: pointer;
                                        white-space: nowrap;
                                        display: inline-flex;
                                        padding: 5px;

                                        .svg-icon {
                                            margin-left: 5px;
                                        }
                                    }

                                    .item:hover {
                                        background-color: #fff;
                                    }
                                }

                                input {
                                    background-color: #fff;
                                    border: solid 1px #d4d4d4;
                                }

                                md-input-container {
                                    margin-top: 10px !important;
                                }
                            }
                        }

                        .empty-list {
                            text-align: center;
                        }

                        .section-header {
                            background-color: #ebeff3;
                            min-height: 40px;

                            &:hover {
                                .actions {
                                    display: block;
                                }
                            }

                            &.blur {
                                opacity: .3;

                                .actions {
                                    display: none !important;
                                }
                            }

                            .section-title {
                                flex: 20;
                                padding: 10px;

                                b:last-child {
                                    display: none;
                                }
                            }

                            .actions {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .type-icon {
        position: relative;
        max-width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        div {
            border-radius: 5px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            .svg-icon {
                width: 24px;
                height: 24px;
            }
        }
    }

    .field-types {
        background-color: #f9f9f9;

        .header {
            padding: 10px;
        }

        &.blur {
            opacity: .3;

            .item {
                cursor: default !important;
            }

            .actions {
                display: none !important;
            }
        }

        .types-list {
            .item {
                position: relative;
                background-color: #fff;
                border: solid 2px #d4d4d4;
                border-radius: 4px;
                margin: 5px;
                padding: 2px;
                cursor: pointer;

                .type-title {
                    flex: 20;
                    margin-left: 4px;

                    .label {
                        color: #606a73;
                    }

                    .description {
                        color: #d4d4d4;
                        margin: 4px 0 0;
                    }
                }

                .actions {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: #fff;
                    display: none;
                }

                &:hover {
                    .actions {
                        display: block;
                    }
                }
            }

        }
    }
}

.drag-indicator {
    display: flex;
    align-items: center;

    .svg-icon {
        width: 33px;
        height: 24px;
    }
}

.draggable-list {
    .item {
        border: solid 2px #dadada;
        border-radius: 4px;
        margin-bottom: 5px;

        .field-header {
            align-items: center;
            min-height: 40px;

            &:hover {
                .actions {
                    display: block;
                }
            }

            .field-title {
                flex: 20;
                padding: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                .label {
                    font-weight: bold;
                }
            }

            .actions {
                display: none;

                .svg-icon {
                    height: 22px;
                    width: 22px;
                }
            }
        }
    }

    .-placeholderAfter {
        padding-bottom: 13px;
    }

    .-placeholderBefore {
        padding-top: 13px;
    }

    .-isEmpty {
        padding-bottom: 40px;
    }

    .-isEmpty:before,
    .-placeholderBefore:before,
    .-placeholderAfter:before {
        background-color: #a0a0a0;
        height: 3px;
        width: 100%;
        content: ' ' !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
}
