.dashboard-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;

    md-input-container {
        padding: 0;
    }

    .label {
        margin-right: 12px;
    }

    .group-select {
        display: flex;
        gap: 30px;
    }

    .select-wrap {
        position: relative;

        .svg-icon {
            display: none;
            fill: #3498db;
            position: absolute;
            left: 10px;
            top: 10px;
            height: 22px;
            width: 22px;
        }
    }

    .ember-power-select-trigger {
        min-width: 180px;
        position: relative;
        z-index: 1;
    }

}

@media (min-width: 1500px) {
    .dashboard-top {
        position: relative;

        &.justifyCenter {
            .wrap-start-note-block {
                position: absolute;
                top: 0;
                left: 40%;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 1500px) {
    .dashboard-top {
        &.justifyCenter {
            .text {
                justify-content: center;
            }
        }
    }
}


.wrap-start-note-block {
    display: flex;
    justify-content: center;

    > div {
        align-items: center;
        border-radius: 8px;
        border: 1px solid #ff9900;
        display: flex;
        width: auto;
        padding: 7px;
    }

    .info-icon {
        height: 24px;
        fill: #ff9900;
        margin-right: 8px;
        width: 24px;
    }
}

.dashboard-page {
    background-color: #f8fafc;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;

    .label {
        margin-right: 12px;
    }

    .satisfaction-row {
        justify-content: center;
        display: flex;

        .svg-icon {
            fill: #219653;
            height: 22px;
            width: 22px;
            transform: scaleX(-1);
        }

        .like, .dislike, .rating {
            width: 30px;
        }

        .dislike-icon {
            fill: #e24b4b;
            transform: rotateX(180deg), scaleX(-1);
        }

        .rating {
            color: #219653;
        }
    }

    h4 {
        font-size: 18px;
        margin: 12px 14px;
        text-align: left;
        font-weight: 500;
        color: #121416;
    }

    .panel-title {
        font-size: 18px !important;
        font-weight: 500 !important;
        color: #121416 !important;
        fill: currentColor !important;
        font-family: "circular", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "sans-serif" !important;
        margin: 5px 10px;
    }

    .row-panels {
        display: flex;
        gap: 15px;

        > div {
            background: #fff;
            border-radius: 2px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            border: 1px solid #ebf0f5;
            flex: 1;

            h3 {
                font-size: 14px;
                font-weight: 500;
                color: #606a73;
                text-transform: uppercase;
            }

            p {
                margin: 5px 0 7px;
                font-weight: bold;
            }
        }

        .dashboard-panel {
            position: relative;
            min-height: 315px;
            box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px;
            border-radius: 5px;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.16) 0 2px 4px;
            }
        }

        .wrap-card {
            color: #121416;
            font-weight: 500;
            padding: 15px;
            position: relative;
            box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px;
            border-radius: 5px;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.16) 0 2px 4px;
            }

            .wrap-icon {
                border-radius: 50%;
                display: flex;
                position: absolute;
                right: 15px;
                top: 15px;

                .svg-icon {
                    width: 30px !important;
                    height: 30px !important;
                }
            }

            .top {
                min-height: 40px;
                padding-right: 60px;
            }

            .middle {
                font-size: 30px;
                margin-bottom: 5px;
            }

            .bottom {
                color: green;
                font-size: 14px;

                .svg-icon {
                    margin-right: 5px;
                    vertical-align: bottom;
                }

                &.down {
                    color: red;

                    .svg-icon {
                        fill: red;
                        transform: scaleY(-1);
                        vertical-align: middle;
                    }
                }
            }

            .svg-icon {
                height: 22px;
                width: 22px;
                fill: green;
            }
        }

        .cards-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .wrap-card {
                border: solid 1px #ebf0f5;
                min-width: 30%;
                max-width: 30%;
                margin: 10px;

                .top {
                    padding-right: 40px;
                }
            }
        }
    }

    .wrap-table {
        border: 1px solid #eee;
        border-radius: 2px;
        margin: 12px !important;
        padding: 0 !important;

        thead {
            th {
                background-color: #fafafa;
                color: #121416;
                font-weight: 400;
            }
        }

        thead tr, tbody tr:not(:last-child) {
            th, td {
                border-bottom: 1px solid #eee;
            }
        }

        th, td {
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }
    }
}

.dates-custom-picker {
    right: 0;
    position: absolute;
    z-index: 1;

    .flatpickr-input {
        display: none;
    }
}

.quick-responses-wrap {
    table {
        width: 100%;

        td, th {
            text-align: left;
            padding: 10px;
        }

        th {
            font-size: 18px;
            font-weight: 500;
            color: #121416;
            text-transform: none;
        }

        tr {
            transition: .2s;
        }
    }

    .show-all {
        border-bottom: 1px solid;
        cursor: pointer;
        display: inline-block;
        margin-top: 10px;
    }
}

.highcharts-credits {
    display: none !important;
}
