.ecommerce-title {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .svg-icon {
        margin-right: 8px;
        height: 22px;
        width: 22px;
    }
}

.container-markets {
    margin-bottom: 20px;
}

.wrap-banner {
    border-radius: 4px;
    border: 1px solid #ececec;
    margin: 10px 0 20px;
    padding: 27px 20px;
    position: relative;
    font-size: 14px;
    color: #4B576D;

    .title-banner {
        color: #121416;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
    }

    .image-banner, .svg-icon {
        height: 120px;
        width: 120px;
        right: 14px;
        position: absolute;
        top: 14px;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.ecommerce-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.ecommerce-tab {
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px;
    border: 1px solid #ececec;
    transition: .2s;
    width: 300px;
    cursor: pointer;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0 2px 4px;
        cursor: grab;
    }

    &.connected {
        background-color: rgba(51, 255, 51, .14);
    }

    svg {
        width: 26px;
        height: 26px;
    }

    .status {
        box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px;
        font-weight: 500;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #eee;

        &.connected {
            background-color: #eff9ea;
            box-shadow: none;
            color: #6ba350;
        }
    }

    .header {
        color: #121416;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 0;
    }

    .info {
        color: #231F20;
        font-size: 14px;
    }

    .tip {
        color: rgba(100, 116, 145, 1);
        font-size: 12px;
        margin-bottom: 10px;
    }
}

.ecommerce-settings {
    padding: 20px 0 0;

    .header {
        color: #121416;
        font-weight: 500;
        font-size: 17px;
        margin-bottom: 40px;

        .logos {
            display: flex;
            gap: 15px;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            img {
                height: 44px;
                width: 44px;
            }

            .svg-icon {
                height: 54px;
                width: 54px;

                &.arrows {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }

    .content {
        color: #121416;

        .title {
            font-weight: 500;
            margin-bottom: 10px;
        }

        .name {
            margin-bottom: 10px;
        }

        .input-group {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .form-group {
            .label {
                color: #121416;
                margin-bottom: 2px;
            }
        }

        .paper-switch {
            margin-bottom: 5px;

            .md-container {
                margin-right: 12px;
            }
        }

        .switches {
            margin-bottom: 30px;
        }
    }

    .details {
        .title-details {
            margin-bottom: 10px;
            display: flex;
            gap: 5px;
            align-items: center;
            justify-content: center;
            font-weight: 500;
        }

        .flexDisplay {
            gap: 30px;

            .form-group {
                flex: 1;

                .label {
                    color: #121416;
                }
            }
        }
    }

    .content {
        .panel {
            margin: 7px 0 0 0;

            .svg-icon {
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }
        }

        .details {
            .head {
                font-weight: 500;
                margin: 10px 0 3px;
            }

            .tag-item {
                background: #ddebff;
                border-radius: 3px;
                color: #2b78e4;
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                margin: 0 4px 5px 0;
                padding: 4px 8px;
            }
        }
    }
}
