@import 'md-variables';

md-backdrop.md-edit-dialog-backdrop {
  z-index: $md-backdrop-index;
}

md-edit-dialog {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: $md-backdrop-index + 1;
  background-color: $md-table-dialog-color;
  border-radius: 2px;
  cursor: default;

  > .md-content {
    padding: 16px 24px 0;
    
    .md-title {
      color: $md-dark;
      margin-bottom: 8px;
    }
    
    md-input-container {
      margin: 0;
      font-size: 13px;
      
      input {
        float: none;
      }
      
      .md-errors-spacer {
        min-height: auto;
        min-width: auto;
        color: $md-dark-secondary;
        
        .md-char-counter {
          padding: 5px 2px 5px 0;
        }
      }
      
      [ng-message] {
        padding: 5px 0 5px 2px;
      }
    }
  }

  > .md-actions {
    margin: 0 16px 8px;
    
    .md-button {
      margin: 0;
      min-width: initial;
      
      & + .md-button {
        margin-left: 8px;
      }
    }
  }
}