md-toolbar.md-table-toolbar {
  box-shadow: none;

  &.md-default-theme:not(.md-menu-toolbar).md-default,
  &:not(.md-menu-toolbar).md-default {
    background-color: $md-light;
    color: rgba(0, 0, 0, 0.87);
    
    .md-button {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  @media only screen and (max-width: $md-media-lt-md) and (min-width: 0) and (orientation: landscape) {
    .md-toolbar-tools {
      height: 64px;
      max-height: initial;
    }
  }

  .md-toolbar-tools {
    padding: 0 24px;
    
    md-icon {
      color: $md-dark-icon;
    }
    
    > .md-button.md-icon-button {
      margin: 0;
    }
    
    > .md-button.md-icon-button:first-child {
      margin-left: -12px;
    }
    
    > .md-button.md-icon-button:last-child {
      margin-right: -12px;
    }
  }
}