// dark text on light backgrounds
$md-dark: rgba(0, 0, 0, 87%);
$md-dark-secondary: rgba(0, 0, 0, 54%);
$md-dark-disabled: rgba(0, 0, 0, 26%);
$md-dark-icon: rgba(0, 0, 0, 54%);
$md-dark-divider: rgba(0, 0, 0, 12%);

md-card {
  > md-toolbar.md-table-toolbar:first-child,
  md-table-container:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  > md-toolbar.md-table-toolbar:last-child,
  md-table-container:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.virtual-table {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

#hidden-row th {
  position: relative;
  height: 0px !important;
  line-height: 0px !important;
  margin: 0px !important;
  overflow: hidden !important;
  border: none !important;
}
#hidden-row th md-icon {
  position: relative;
  height: 0px !important;
  line-height: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
  overflow: hidden !important;
  border: none !important;
}

md-table-container {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}


table.md-table {
  width: 100%;
  border-spacing: 0;
  overflow: hidden;

  thead.md-head > tr.md-row {
    height: 56px;
  }

  tbody.md-body > tr.md-row,
  tfoot.md-foot > tr.md-row {
    height: 48px;
  }

  thead.md-head + .md-table-progress md-progress-linear {
    top: -3px;
  }

  .md-table-progress th {
    padding: 0;

    md-progress-linear {
      height: 0;
      transition: opacity 1s;

      &.ng-hide {
        opacity: 0;
      }

      > .md-container {
        height: 3px;
        top: 0;
        transition: none;

        > .md-bar {
          height: 3px;
        }
      }
    }
  }

  th.md-column {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;

    &.md-sort {
      cursor: pointer;
    }

    md-icon {
      height: 16px;
      width: 16px;
      font-size: 16px !important;
      line-height: 16px !important;

      &.md-sort-icon {
        color: rgba(0, 0, 0, 26%);
        opacity: 0;
        transition: transform 0.25s, opacity 0.25s;

        &.md-asc {
          transform: rotate(0deg);
        }

        &.md-desc {
          transform: rotate(180deg);
        }
      }

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &.md-active,
    &.md-active md-icon {
      color: rgba(0, 0, 0, 0.87);
    }

    &:hover md-icon.md-sort-icon,
    &.md-active md-icon.md-sort-icon {
      opacity: 1;
    }
  }

  tr.md-row {
    &[ng\:repeat].ng-leave,
    &[ng-repeat].ng-leave,
    &[x-ng-repeat].ng-leave,
    &[data-ng-repeat].ng-leave {
      display: none;
    }
  }

  &.md-row-select tbody.md-body > tr.md-row {
    transition: background-color 0.2s;

    &:not([disabled]):hover {
      background-color: #eee !important;
    }

    &.md-selected {
      background-color: #f5f5f5;
    }
  }

  &.md-row-select td.md-cell,
  &.md-row-select th.md-column {
    &:first-child {
      width: 20px;
      padding: 0 0 0 24px;
    }

    &:nth-child(2) {
      padding: 0 24px;
    }

    &:nth-child(n+3):nth-last-child(n+2) {
      padding: 0 56px 0 0;
    }
  }

  &:not(.md-row-select) td.md-cell,
  &:not(.md-row-select) th.md-column {
    &:first-child {
      padding: 0 24px;
    }

    &:nth-child(n+2):nth-last-child(n+2) {
      padding: 0 56px 0 0;
    }
  }

  td.md-cell,
  th.md-column {
    vertical-align: middle;
    text-align: left;

    > * {
      vertical-align: middle;
    }

    &:last-child {
      padding: 0 24px 0 0;
    }

    &.md-clickable {
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    &.md-numeric {
      text-align: right;
    }

    md-checkbox {
      margin: 0;
      width: 20px;
    }
  }

  td.md-cell {
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    border-top: 1px rgba(0, 0, 0, 0.12) solid;
    
    &.md-numeric md-select {
      justify-content: flex-end;

      .md-select-value {
        flex: 0 0 auto;
      }
    }

    &.md-placeholder {
      color: rgba(0,0,0,0.26);
    }

    md-select > .md-select-value > span.md-select-icon {
      justify-content: flex-end;
      color: rgba(0, 0, 0, 0.54);
      width: 18px;
      text-align: right;

      &:after {
        transform: scaleY(0.4) scaleX(0.8);
      }
    }
  }
}