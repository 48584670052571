$md-backdrop-index: 80;

// dark text on light backgrounds
$md-dark: rgba(0, 0, 0, 0.87) !default;
$md-dark-secondary: rgba(255, 0, 0, 0.54) !default;
$md-dark-disabled: rgba(0, 0, 0, 26%) !default;
$md-dark-icon: $md-dark-secondary !default;
$md-dark-divider: rgba(0, 0, 0, 12%) !default;

// white text on dark backgrounds
$md-light: rgba(255, 255, 255, 100%);
$md-light-secondary: rgba(255, 255, 255, 70%);
$md-light-disabled: rgba(255, 255, 255, 30%);
$md-light-icon: $md-light;
$md-light-divider: rgba(255, 255, 255, 12%);

// table
$md-dark-row-hover: #eee;
$md-dark-row-select: #f5f5f5;

// media small
$md-media-sm: 600px;
$md-media-lt-sm: calc($md-media-sm - 1px);
$md-media-gt-sm: calc($md-media-sm + 1px);

// media medium
$md-media-md: 960px;
$md-media-lt-md: calc($md-media-md - 1px);
$md-media-gt-md: calc($md-media-md + 1px);

// media large
$md-media-lg: 1200px;
$md-media-lt-lg: calc($md-media-lg - 1px);
$md-media-gt-lg: calc($md-media-lg + 1px);

$md-table-dialog-color: #f9f9f9;