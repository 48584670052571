.channels-select {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    color: #121416;

    .ember-power-select-trigger {
        margin-left: 10px;
        width: 245px;
    }
}

.widget-settings {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .dropzone {
        display: none;
    }

    .dz-clickable {
        &:hover {
            .action-btn {
                display: flex;
            }
        }
    }

    .upload-image {
        background-color: #c3c3c3;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        margin-bottom: 12px;
        height: 60px;
        width: 60px;
        border-radius: 50%;
    }

    .inline-flex {
        display: inline-flex;
        align-items: center;
    }

    .md-input {
        background-color: #fff;
    }

    .number-input {
        width: 30% !important;
        min-width: 180px;
    }

    .channels {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .channel {
            text-align: center;

            .paper-switch {
                margin: 6px auto 0;
            }
        }
    }

    .working-hours {
        margin-bottom: 15px;
    }

    .form {
        flex: 1;
        margin-right: 20px;
        overflow: hidden;

        .title-form {
            color: #121416;
            font-weight: 500;
            margin: 0 0 15px;

            &:not(:first-child) {
                margin-top: 25px;
            }
        }

        .title-icon {
            position: absolute;
            left: 18px;
            top: 17px;
            height: 32px;
            width: 32px;
        }

        .panel-title {
            font-weight: 700;
            margin-bottom: 5px;
        }

        .title-label {
            border-top: 1px solid #eee;
            margin: 20px 0 12px;
            padding-top: 20px;
        }

        .label-icon {
            margin-right: 5px;
            height: 20px;
            width: 20px;
            vertical-align: middle;
        }

        .connect-app {
            background-color: #eee;
            border-radius: 10px;
            cursor: pointer;
            height: 80px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:after {
                border: 2px dashed #ddd;
                content: "";
                border-radius: 10px;
                position: absolute;
                height: calc(100% - 20px);
                box-sizing: border-box;
                top: 10px;
                left: 10px;
                width: calc(100% - 20px);
            }

            &.active {
                &:after {
                    border-color: #2B78E4;
                }
            }

            .title {
                padding: 10px;
                font-weight: 500;
            }

            .flexDisplay {
                border-top: 1px solid #eee;
                padding: 10px;

                &:hover {
                    background-color: #eee;
                }

                &:last-child {
                    border-radius: 0 0 10px 10px;
                }

                .label-icon {
                    height: 34px;
                    width: 34px;

                    &.icon-home {
                        fill: #2B78E4;
                    }
                }

                .name-app {
                    font-weight: 500;
                    margin-bottom: 3px;
                }
            }
        }

        .article-block {
            margin-bottom: 10px;
        }

        .list-app {
            padding: 5px;

            .app-item {
                color: #1c7430;
                margin-bottom: 5px;

                &.EC-bigcommerce-logo {
                    color: #000;
                }

                .label-icon {
                    height: 34px;
                    width: 34px;
                    margin-right: 6px;
                }
            }
        }

        .connect-app-popup {
            box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, .15);
            background-color: #fff;
            border-radius: 10px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 350px;
            z-index: 1;
        }

        .label {
            display: flex;
            align-items: center;
            margin-bottom: 7px;

            .svg-icon + .paper-switch {
                margin-left: 7px !important;
            }
        }

        .radio-btn {
            margin-right: 30px;
        }

        .action-btn {
            display: none;
            margin-left: 8px;

            span {
                cursor: pointer;
                margin: 0 3px;
            }
        }

        .action-icon {
            height: 18px;
            width: 18px;
        }

        .theme-color-field {
            align-content: center;
            justify-content: space-between;
            background: #fff;
            border: 1px solid #eeecec;
            border-radius: 5px;
            display: flex;
            padding: 5px;
            margin-bottom: 15px;
            width: 180px;
            position: relative;
        }

        .pickr {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;

            .pcr-button {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
            }
        }

        .droplet-icon {
            margin-right: 3px;
            height: 18px;
            width: 18px;
            vertical-align: bottom;
        }

        .color-palette-icon {
            fill: #dadada;
            height: 20px;
            width: 20px;
        }

        .md-input:focus {
            border-color: #2b78e4 !important;
        }

        .clock-icon {
            margin-top: 12px;
            height: 16px;
            width: 16px;
        }

        .domains-info {
            font-style: italic;
            font-size: 12px;
            margin-top: 10px;
        }

        .paper-switch {
            display: inline-block;
            margin: 0 6px 0 0;
            vertical-align: middle;
            height: auto;

            .md-container {
                margin: 0;
            }
        }

        .channel-icon {
            height: 48px;
            width: 48px;
        }

        .cp-Panel {
            background: #f9f9f9;
            border-radius: 2px;
            border: 1px solid #dadada;
            box-shadow: 0 0 2px 1px rgba(0, 0, 0, .08);
            padding: 0 40px 0 70px;
            margin-bottom: 8px;

            &.cp-is-open {
                border-color: #2b78e4;
            }

            .ember-power-select-trigger {
                width: 200px;
                margin-right: 30px;
            }
        }

        .cp-Panel-toggle {
            color: #5d5e64;
            font-size: 13px;
            padding: 15px 0 17px;
            display: flex;

            &.cp-is-open {
                .panel-title {
                    color: #2b78e4;
                }

                .title-icon {
                    fill: #2b78e4;
                }

                .arrow-icon {
                    transform: rotate(180deg);
                }
            }
        }

        .cp-Panel-body {
            &.cp-is-open {
                padding: 10px 0;
            }

            &.integration {
                .ember-power-select-trigger {
                    margin-top: 10px;
                }
            }

            .channels {
                gap: 20px;
                margin: 15px 0;
            }

            .md-list-item-inner {
                padding: 0;
            }

            md-input-container {
                width: 90%;
            }
        }

        .arrow-icon {
            position: absolute;
            right: 15px;
            top: 15px;
            transition: .2s;
            transform: rotate(90deg);
            height: 16px;
            width: 16px;
        }

        .wp-icon {
            height: 32px;
            width: 32px;
        }

        .option-title {
            margin-bottom: 10px;
        }

        .code-snippet {
            border-radius: 5px;
            border: 1px solid #2b78e4;
            position: relative;
            padding: 10px;
            margin-bottom: 20px;

            .copy-button {
                background: #2b78e4;
                border-radius: 5px;
                color: #fff;
                padding: 0 10px;
                position: absolute;
                top: 5px;
                right: 3px;
                min-width: auto;
            }

            .copy-icon {
                vertical-align: middle;
                margin-right: 6px;
                fill: #fff;
                height: 16px;
                width: 16px;
            }
        }
    }

    .wrap-widget {
        margin-top: 33px;
        width: 310px;

        .image-launcher {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border-radius: 50%;
            border: 1px solid #dadada;
            box-shadow: 0 2px 15px rgb(0 0 0 / 15%);
            cursor: pointer;
            float: right;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            width: 35px;

            .icon-launcher {
                height: 30px;
                width: 30px;
                fill: #fff;
                transform: rotate(90deg);
            }
        }
    }

    .widget {
        border-radius: 5px;
        border: 1px solid #dadada;
        //height: calc(100% - 120px);
        font-family: "Helvetica-Roman", 'sans-serif';
        min-height: 400px;
        //max-height: 500px;
        //overflow-y: scroll;
        font-size: 12px;
        position: relative;
        margin-bottom: 12px;

        .top-widget {
            background-color: #1e20a8;
            border-radius: 5px 5px 0 0;
            box-shadow: rgb(0 0 0 / 20%) 0 3px 8px 1px;
            color: #fff;
            font-size: 16px;
            margin: -1px;
            padding: 15px 15px 85px 30px;
            position: relative;

            .overlay-head {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: .15;
                margin: -1px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            .user-name {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 4px;
            }

            img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                margin-bottom: 10px;
            }
        }

        .channel-icon {
            height: 35px;
            width: 35px;
        }

        .widget-cards {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: -55px;
            padding: 10px 0;
            z-index: 1;
        }

        .channels-widget, .info-widget, .tracking-widget {
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
            border-top: 2px solid transparent;
            width: 92%;
            padding: 15px;
            position: relative;

            md-input-container {
                width: 100%;
            }

            .action-buttons {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }

            .search-wrap {
                position: relative;
                margin-bottom: 10px;

                .search-btn {
                    border-radius: 0 5px 5px 0;
                    position: absolute;
                    width: 40px;
                    height: 100%;
                    background-color: #2B78E4;
                    right: 0;
                    top: 0;
                    text-align: center;

                    .svg-icon {
                        fill: #fff;
                        height: 22px;
                        width: 22px;
                        margin-top: 8px;
                    }
                }
            }
        }

        .wrap-orders {
            margin-top: 10px;
        }

        .track-block {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .item-order {
            cursor: pointer;
            margin-bottom: 10px;

            .name-order {
                color: $AdminMenuBlueColor;
                font-weight: 500;
            }

            .fulfilled, .delivered, .confirmed {
                background-color: #eee;
                color: $AdminMenuBlueColor;
                display: inline-block;
                font-size: 10px;
                padding: 1px 6px;
                margin: 0 3px;
            }

            .delivered {
                background-color: green;
                color: #fff;
            }

            .date-order {
                color: $TextGreyColor;
                margin-top: 2px;
            }
        }

        .back-btn {
            color: #6f5cb9;
            cursor: pointer;
            font-size: 13px;
            display: inline-flex;

            .arrow-icon {
                height: 16px;
                width: 16px;
                fill: #6f5cb9;
                transform: rotate(-90deg);
            }
        }

        .track-icon {
            height: 24px;
            width: 24px;
            margin-right: 6px;
        }

        .inner-widget-title {
            color: #5d5e64;
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 12px;
        }

        .info-widget {
            img {
                margin-right: 4px;
                vertical-align: middle;
                height: 30px;
                width: 30px;
                border-radius: 50%;
            }

            .drop-line-button {
                background-color: #2b78e4;
                border-radius: 5px;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                padding: 5px 10px;
                margin: 16px 0 0 0;
            }

            .telegram-icon {
                height: 15px;
                width: 15px;
                vertical-align: middle;
                margin-right: 5px;
            }
        }
    }
}

.channel-app-icon {
    width: 24px;
    height: 24px;
}

.blink {
    display: initial !important;
    animation: blink-animation 2s infinite;
    -webkit-animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
    from { opacity: 1.0; }
    50% { opacity: 0.4; }
    to { opacity: 1.0; }
}

@-webkit-keyframes blink-animation {
    from { opacity: 1.0; }
    50% { opacity: 0.4; }
    to { opacity: 1.0; }
}
