.administration-menu {
    padding: 10px;

    .menu-title {
        color: $TitleColor;
        font-size: 16px;
        font-weight: 500;
        margin: 15px 10px;
    }

    .svg-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .menu-link {
        font-weight: 500;
        padding: 10px;
        font-size: 14px;

        &:hover {
            a {
                color: $AdminMenuBlueColor;

                .svg-icon {
                    fill: $AdminMenuBlueColor;
                }
            }
        }

        &.first, &.last {
            padding-left: 0;
        }

        &.last {
            .svg-icon {
                width: 24px;
                height: 24px;
            }
        }

        &.active {
            a {
                color: $AdminMenuBlueColor;
            }

            .svg-icon {
                fill: $AdminMenuBlueColor;
            }
        }

        a {
            align-items: center;
            color: #656a72;
            display: flex;
        }
    }

    .cp-Panel {
        .cp-Panel-toggle {
            align-items: center;
            display: flex;
            color: #656a72;
            font-weight: 500;
            justify-content: space-between;
            position: relative;
            padding: 10px 0;

            .arrow {
                transition: transform .3s;
            }

            &:hover {
                color: $AdminMenuBlueColor;

                .svg-icon {
                    fill: $AdminMenuBlueColor;
                }
            }

            &.cp-is-open {
                .arrow {
                    transform: rotate(90deg);
                }
            }
        }

        .cp-Panel-body-inner {
            padding: 0 0 0 15px;
        }
    }
}

.profile-block {
    display: flex;
    margin: 10px 0 0;

    .profile-wrap-avatar {
        margin: 0 90px 0 50px;
    }

    .dropzone {
        display: none;
    }

    .loader div {
        height: 10px;
        width: 10px;
    }

    .avatar-upload {
        border: 1px solid #ddd;
        border-radius: 10px;
        margin: auto;
        position: relative;
        width: 220px;
        height: 220px;

        &:hover {
            .edit-icon, .remove-icon {
                display: flex;
            }
        }

        img {
            border-radius: 10px;
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .edit-icon, .remove-icon {
        align-items: center;
        justify-content: center;
        background-color: #2b78e4;
        border-radius: 50%;
        cursor: pointer;
        display: none;
        height: 26px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 26px;

        > svg {
            fill: #fff;
            height: 15px;
            width: 15px;
        }
    }

    .remove-icon {
        bottom: 40px;
    }

    .setting-form {
        width: 400px;

        .action-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
    }
}

.settings-content {
    h3 {
        color: #121416;
        font-weight: 500;
    }

    .setting-form {
        width: 400px;

        .form-group {
            .label {
                width: 30%;
            }
        }
    }
}

.form-label {
    padding: 10px 10px 10px 0;
    display: inline-block;
    text-align: left;
    vertical-align: top;
}

.onboard-wrap {
    padding: 10px;

    .left-column {
        border: 2px solid #eee;
        border-radius: 5px;
        width: 300px;

        .column-inner {
            padding: 10px;

            h4 {
                font-weight: normal;
                margin: 18px 0 5px;
            }
        }

        .sign-up-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .paper-icon {
                color: green;
                margin: 0 6px;
            }
        }

        .progress-bar-info {
            background-color: #eee;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 10px;
            margin: -1px;
            color: #121416;

            span {
                font-size: 14px;
                font-weight: bold;
            }

            .progress-circle {
                display: flex;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                background-color: #ebebeb;
                position: relative;
            }

            .progress-circle:after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                -webkit-animation: colorload 2s;
                animation: colorload 2s;
            }

            .progress-circle span {
                font-size: 10px;
                color: #8b8b8b;
                position: absolute;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 50%;
                background: #fff;
            }

            .progress-circle span:after {
                content: "%";
                font-weight: 600;
                color: #8b8b8b;
            }

            .progress-circle.progress-20:after {
                background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent),
                linear-gradient(208.8deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            .progress-circle.progress-40:after {
                background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent),
                linear-gradient(259.2deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            .progress-circle.progress-60:after {
                background-image: linear-gradient(-25.2deg, #66b8ff 50%, transparent 50%, transparent),
                linear-gradient(270deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            .progress-circle.progress-80:after {
                background-image: linear-gradient(25.2deg, #66b8ff 50%, transparent 50%, transparent),
                linear-gradient(270deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            .progress-circle.progress-100:after {
                background-image: linear-gradient(90deg, #66b8ff 50%, transparent 50%, transparent),
                linear-gradient(270deg, #66b8ff 50%, #ebebeb 50%, #ebebeb);
            }

            @-webkit-keyframes colorload {
                0% {
                    opacity: 0;
                    transform: rotate(0deg);
                }
                100% {
                    opacity: 1;
                    transform: rotate(360deg);
                }
            }
        }
    }

    .onboard-menu-item {
        cursor: pointer;
        display: flex;
        padding: 10px 5px;
        justify-content: space-between;

        &.disabled {
            .paper-icon:first-child, span {
                opacity: .4;
            }
        }

        .check-icon {
            color: green;
            font-weight: bold;
            margin: 0;
        }

        &.active {
            background: $AdminMenuBlueColor;
            color: #fff;

            .paper-icon {
                color: #fff;
            }
        }

        .svg-icon {
            margin-right: 7px;
            height: 20px;
            width: 20px;
        }
    }

    .onboard-profile {
        flex: 1;
        padding: 0 20px;
        max-width: 750px;

        h3 {
            color: #121416;
            font-weight: 400;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .svg-icon {
                height: 30px;
                width: 30px;
                margin-right: 10px;
            }
        }

        .profile-wrap-avatar {
            margin: 0 20px 0 0;
        }

        .form-control input {
            height: auto;
            margin: 0;
            padding: 10px 14px;
        }

        .avatar-upload {
            height: 180px;
            width: 180px;

            .svg-icon {
                height: 120px;
                width: 120px;
            }
        }

        .profile-info {
            flex: 1;

            .form-group {
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                md-input-container {
                    width: 100%;
                }
            }

            .label {
                margin-bottom: 0;
                width: 120px;
            }
        }

        .language-confirm {
            align-items: end;
            display: flex;
            justify-content: space-between;
            margin-top: 25px;

            .ember-power-select-trigger {
                margin-top: 10px;
                width: 180px;
            }

            .md-button {
                margin-right: 15px;
            }
        }

        .action-buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
        }

        .mailbox-wrap {
            background-color: #ddebff;
            border-radius: 5px;
            padding: 15px;
            margin-top: 15px;

            .mailbox-info {
                margin-bottom: 15px;
            }

            .field-address-wrap {
                display: flex;
                align-items: center;

                .md-button {
                    margin-left: 10px;
                    min-height: 38px;
                }
            }
        }

        .top-personalised {
            background-color: #ddebff;
            border-radius: 5px;
            padding: 15px;

            .title-inner {
                color: #121416;
                margin-bottom: 10px;
            }

            .wrap-domain {
                background-color: #fff;
                border-radius: 5px;
                flex: 1;
                align-items: center;
                display: flex;
                padding: 0 10px;

                span {
                    display: inline-block;
                    font-size: 16px;
                }
            }

            .domain-input {
                align-items: center;

                input {
                    background-color: #fff;
                    font-size: 16px;
                    padding: 0;
                    border: none !important;
                }
            }

            .primary-button {
                border-radius: 5px;
                margin: 0 0 0 10px;
                max-height: 40px;
            }

            .form-group {
                display: flex;
                margin: 10px 0 0;
            }

            img {
                margin-right: 15px;
                width: 100px;
            }
        }

        .action-button-form {
            margin-top: 15px;
        }
    }

    .onboard-title {
        padding: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, .07);
        margin-bottom: 15px;
        justify-content: flex-end;
        display: flex;
    }

    .invite-form {
        width: 90%;

        .label {
            color: #4d4c4f;
            font-weight: 500;
            margin-bottom: 7px;
        }

        .paper-switch {
            margin: 0 0 2px;
            display: inline-flex;
        }

        .two-field {
            display: flex;
            margin-bottom: 15px;

            .md-checkbox {
                margin: 0;
            }

            .ember-power-select-trigger {
                width: 100%;
            }
        }
    }

    .cp-Panel {
        border: 1px solid #eee;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 12px 15px;

        .cp-Panel-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 500;

            &.cp-is-open {
                color: #2b78e4;

                .arrow-icon {
                    fill: #2b78e4;
                    transform: rotate(0deg);
                }
            }

            .arrow-icon {
                transform: rotate(90deg);
                transition: .2s;
                height: 16px;
                width: 16px;
            }
        }

        .cp-Panel-body.cp-is-open {
            margin-top: 20px;
        }
    }

    .title-instruction {
        margin-bottom: 20px;
    }

    .list-instruction li {
        margin-bottom: 15px;

        img {
            display: inline-block;
            max-width: 80%;
        }
    }
}

.progress-bar-wrap {
    cursor: pointer;
    text-align: center;
    width: 100px;

    .progress-bar-title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .progress-bar {
        height: 4px;
        background: lightgreen;

        .progress-bar-active {
            background: green;
            height: 100%;
            width: 20px;

            &.progress-40 {
                width: 40px;
            }

            &.progress-60 {
                width: 60px;
            }

            &.progress-80 {
                width: 80px;
            }

            &.progress-100 {
                width: 100%;
            }
        }
    }
}

.security-block {
    padding: 10px 40px 0;
    width: 440px;

    .eye-icon, .checkmark-circle {
        height: 20px;
        width: 20px;
    }

    .form-group {
        position: relative;
    }

    .user-status {
        bottom: 0;
        display: inline-block;
        margin-right: 5px;
        position: relative;
    }

    .full-name {
        color: #231f20;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 7px;
    }

    .alert-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        right: -40px;
        top: 28px;
        fill: #d62516;
    }

    .title-avatar {
        border: 1px solid #ddd;
        border-radius: 10px;
        height: 115px;
        width: 115px;
        margin: 0 20px 35px 0;

        img {
            border-radius: 10px;
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .svg-icon-wrap {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .action-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    md-input-container {
        width: 100%;
    }

    .validation-container {
        .svg-icon {
            margin-right: 7px;
        }

        .item-validate {
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            &.valid {
                color: #54a702;

                .svg-icon {
                    fill: #54a702;
                }
            }
        }
    }
}

.profile_upload {
    .avatar-upload {
        border-radius: 5px;
        display: flex;
        margin: 0 auto 15px;
        width: 80px;
        height: 80px;
        cursor: pointer;
        position: relative;

        .person-icon {
            height: 34px;
            margin: auto;
            width: 34px;
        }

        .plus-icon {
            height: 24px;
            position: absolute;
            right: -10px;
            bottom: -2px;
            width: 24px;
        }

        img {
            max-width: 100%;
            margin: 0 auto;
            object-fit: cover;
        }
    }

    .svg-icon {
        height: 80px;
        width: 80px;
    }

    .dropzone {
        display: none;
    }

    small {
        color: #adadad;
        font-size: 12px;
        font-weight: 400;
    }

    .remove-avatar {
        cursor: pointer;
        display: inline-block;
        margin-bottom: 10px;
        font-size: 12px;
    }
}

.avatar-block {
    position: relative;
    margin-right: 20px;

    &._cursor {
        cursor: pointer;
    }

    img {
        border: 1px solid $AdminMenuBlueColor;
    }
}

.user-profile {
    border-right: 1px solid #dadada;
    width: 240px;

    .profile-title {
        color: #231f20;
        font-weight: 500;
        font-size: 16px;
        padding: 15px 20px 20px;
    }

    .profile-menu {
        border-radius: 4px;
        margin: 5px;
        padding: 10px;

        &:hover {
            background-color: $AdminMenuBlueColor;

            a {
                color: #fff;
            }
        }

        a {
            display: flex;
        }
    }

    .icon-bell {
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }

    .label {
        font-weight: 500;
        font-size: 14px;
    }
}

.user-status {
    background-color: #39cb58;
    border: 1px solid #fff;
    bottom: 2px;
    border-radius: 50%;
    color: #39cb58;
    position: absolute;
    right: 0;
    height: 12px;
    width: 12px;

    &.away {
        background-color: #ffbf00;
        color: #ffbf00;
    }

    &.offline {
        background-color: #cacaca;
        color: #cacaca;
    }

    &.snooze {
        &::after {
            background-color: #fff;
            border-radius: 50%;
            content: "";
            height: 6px;
            width: 6px;
            position: absolute;
            right: -3px;
            bottom: 4px;
        }

        &::before {
            content: "z";
            font-size: 8px;
            font-weight: 700;
            line-height: normal;
            position: absolute;
            right: -3px;
            bottom: 3px;
            z-index: 1;
        }
    }
}

.notification-form {
    padding: 20px;

    .notification-form-group {
        margin-bottom: 15px;
    }

    .notification-warn {
        border-radius: 4px;
        background-color: rgba(224, 30, 90, .1);
        border: 1px solid rgba(224, 30, 90, .4);
        padding: 12px 16px;
        display: flex;

        .svg-icon {
            fill: #e01e5a;
            margin-right: 5px;
            height: 20px;
            width: 20px;
        }
    }

    .paper-switch, .md-checkbox {
        margin: 0;
    }

    .notification-form_icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }

    .notification-form_title {
        margin-left: 34px;

        .label {
            color: #5d5e64;
            font-weight: 500;
            margin-right: 10px;
        }

        .snooze-title {
            margin: 5px 10px 10px 0;
        }

        .label-field {
            margin-bottom: 5px;
        }

        .checkbox-field {
            margin-top: 12px;

            .md-label {
                margin-top: 3px;
            }
        }

        .ember-power-select-trigger {
            width: 245px;
        }
    }
}

.md-dialog-container {
    .flatpickr-input {
        background-color: transparent;
        padding: 12px 10px;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    .date-wrap-field {
        width: 245px;
        margin-bottom: 12px;
        position: relative;

        .calendar-icon {
            cursor: pointer;
            height: 18px;
            width: 18px;
            position: absolute;
            bottom: 14px;
            right: 14px;
            z-index: 0;
        }
    }
}
