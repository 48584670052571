@import 'editor-emojis';
@import 'common/colors';
@import url('https://cdn.quilljs.com/1.0.0/quill.snow.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@font-face {
    font-family: 'Helvetica-Roman';
    src: url('/assets/fonts/HelveticaNeue-Roman.woff2') format('woff2');
}

@import url('https://fonts.cdnfonts.com/css/euclid-circular-b');

html,
body {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

body {
    background-color: #fff;
    color: $TextLightGreyColor;
    font-size: 15px;
    //font-family: 'circular', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif';
    font-family: 'Euclid Circular B', 'circular', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', sans-serif;
    cursor: default;

    > .ember-view {
        height: 100%;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dz-preview {
    display: none;
}

a {
    color: $TextLightGreyColor;
    text-decoration: none;
}

ul {
    list-style: none;
}

.main-wrapper {
    /*position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;*/
    display: flex;
    height: 100%;

    .liquid-container {
        transform: none;
    }

    .emoji {
        margin: 0 3px;
        background-image: url('https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png');
        cursor: pointer;
        width: 22px;
        height: 22px;
        font-size: 0;
        background-size: 6100% 6100%;
        display: inline-block;
        vertical-align: text-bottom;
    }
}

.emoji {
    margin: 0 3px;
    background-image: url('https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png');
    cursor: pointer;
    width: 22px;
    height: 22px;
    font-size: 0;
    background-size: 6100% 6100%;
    display: inline-block;
    vertical-align: text-bottom;
}

.page-content {
    border-left: 1px solid rgba(218, 218, 218, 0.5);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    max-height: 100%;
    margin-left: -12px;
    background-color: $BodyColor;
    overflow: auto;
}

.page-header {
    align-items: center;
    border-bottom: solid 1px #ececec;
    display: flex;
    height: 62px;
    z-index: 10;

    .dynamic-content {
        width: 250px;
    }

    .global-search {
        flex: 10;

        .search-icon {
            width: 24px;
            height: 24px;
            fill: $TextLightGreyColor;
        }
    }

    .onboard-title {
        margin-right: 15px;
        display: flex;
    }

    .wrapper-user-popup {
        border-left: solid 1px #ececec;
        padding: 0 29px;

        .user-name {
            color: #121416;
            margin-bottom: 5px;
            font-size: 15px;
        }

        .menu-icon {
            width: 20px;
            height: 20px;
            margin: 0 8px;
            fill: $TextLightGreyColor;
        }

        .apps-popup-menu {
            margin-top: 10px;

            .menu-link {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                &:hover {
                    color: $AdminMenuBlueColor;
                }
            }

            .ember-basic-dropdown-trigger {
                cursor: pointer;
                display: flex;
                align-items: center;

                &:hover {
                    color: $AdminMenuBlueColor;

                    svg {
                        fill: $AdminMenuBlueColor;
                    }
                }

                .arrow-icon {
                    margin: 2px 0 0 8px;
                    height: 18px;
                    width: 18px;
                }
            }
        }

        .sign-out-btn-place {
            padding-top: 17px;
            border-top: solid 1px #E5E5E5;
            margin-top: 6px;

            .sign-out-btn {
                display: inline-flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    color: $AdminMenuBlueColor;

                    svg {
                        fill: $AdminMenuBlueColor;
                    }
                }
            }
        }
    }
}

.page-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;

    .admin-menu {
        &.closed {
            width: 0;

            * {
                display: none;
            }
        }
    }

    &.admin {
        .page-title {
            align-items: center;
            flex-direction: row;
        }
    }

    &.dashboard {
        .page-inner-content {
            margin: 0;
        }
    }

    .page-title {
        flex-direction: column;

        .text {
            flex: 1;
            align-items: center;
            display: flex;
        }
    }

    .title-tasks,
    .buttons-panel {
        width: 100%;
    }

    .title-tasks,
    .page-title {
        display: flex;

        .primary-button,
        .primary-2-button,
        .secondary-button,
        .secondary-2-button {
            display: inline-flex;
            margin: 0 0 0 15px;

            .svg-icon {
                margin-right: 4px;
            }
        }

        .primary-button {
            .paper-icon {
                color: #fff;
            }
        }
    }
}

.pulse-btn {
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .7px;
    display: inline-flex;
    height: 44px;
    border-radius: 5px;
    background-color: #1E20A8;
    margin-left: 20px;
    vertical-align: middle;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;

    &.active {
        animation: 2s pulse-anim infinite;

        &:hover {
            animation: none;
        }
    }

    &.disabled {
        background-color: #c4c4c4;
        border-color: #c4c4c4;
        color: #fff;
        pointer-events: none;
    }

    &.second {
        animation-delay: .5s;
    }

    .svg-icon {
        fill: #fff;
        margin-right: 5px;
    }
}

@keyframes pulse-anim {
    0% {
        box-shadow: #1E20A8 0 0 0 0;
    }
    100% {
        box-shadow: #1E20A800 0 0 0 20px;
    }
}

.admin-menu {
    border-right: 1px solid #ececec;
    transition: .2s;
    width: 250px;

    &.user-profile {
        width: 370px;
        background: none;
    }

    .settings-menu-heading {
        margin: 5px 6px 14px;
    }

    .profile-settings-menu {
        .cp-Panel-toggle {
            margin-bottom: 15px;
            text-transform: none;
            font-size: 14px;

            .arrow-forward {
                top: 2px;
            }
        }
    }
}

.page-inner-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: .3s;

    .page-title {
        position: relative;
        margin: 20px 18px 0 20px;
        min-height: 34px;

        .expand-button {
            border-radius: 0;
            line-height: 38px;
            width: 24px;
            height: 24px;
            padding: 0;
            margin-right: 11px !important;
        }

        h2 {
            align-items: center;
            color: $TextBlackColor;
            display: inline-flex;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            margin-right: 15px;

            &.task-title {
                padding: 10px 15px;
                float: left;
            }

            .title-icon {
                fill: $TextBlackColor;
                height: 24px;
                width: 24px;
                margin-right: 10px;
            }
        }
    }

    .page-inner-content {
        flex: 1;
        margin: 20px;

        .action-button {
            margin-top: 10px;
        }

        .scrollable-content {
            height: 100%;
        }
    }

    .page-footer {
        margin: 0 20px 20px 20px;
    }

    &.wrap {
        box-shadow: none;
        margin: 0;
    }

    .md-button.md-icon-button {
        margin: 0;

        .svg-icon {
            height: 24px;
            width: 24px;
        }
    }

    .title-avatar {
        margin-right: 15px;
        background: greenyellow !important;
    }
}

.flex-1 {
    flex: 1;
}

.preview-image {
    object-fit: contain;
    height: 70px;
    width: 70px;

    img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }
}

@import 'ember-paper';
@import 'paper-data-table';
@import 'ember-paper-stepper';

@import 'color-palette';

@import 'core/loading';
@import 'core/dashboard';
@import 'core/import';
@import 'core/emoji';
@import 'core/forms';
@import 'core/flags';
@import 'core/profile';
@import 'core/autorization';
@import 'shared/default';
@import 'inbox/chat';
@import 'inbox/contacts';
@import 'inbox/flow';
@import 'inbox/channels';
@import 'inbox/ecommerce';
@import 'inbox/kb';

@import 'common/buttons';
@import 'common/dialogs';
@import 'common/sidenav';
@import 'common/RTL';
