@import 'mixin';

.tse-scrollable .tse-scrollbar {
    z-index: 10;
    //z-index: -1;

    .drag-handle {
        //width: 0; // hidden scrollbar custom
        width: 2px;
        background-color: lawngreen;
        //background-color: #ddd;
    }
}

.setting-form {
    margin: 15px 0 0;

    .form-row {
        margin-right: -7.5px;
        margin-left: -7.5px;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }

    .primary-button {
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 10px;
        flex-grow: 1;
        position: relative;

        &.last {
            margin-bottom: 0;
        }

        label {
            color: #656a72;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.7;
            margin-bottom: 2px;
            display: inline-block;
        }
    }
}

.side-menu {
    //background-color: #15163f;
    height: 100%;
    max-height: 100%;
    text-align: center;
    width: 80px;

    .scrollable-content {
        margin-right: 12px;
    }

    .menu-item {
        margin: 30px 0;
        position: relative;

        &:first-child {
            height: 62px;
            margin: auto;

            div, svg {
                opacity: 1;
            }

            svg, img {
                width: 40px;
                height: 40px;
            }

            .nav-item:after {
                display: none;
            }
        }

        &:not(:first-child):hover, &:not(:first-child).active {
            a {
                background-color: #effaff;
                border-color: #2B78E420;
                opacity: 1;

                .svg-icon {
                    fill: $AdminMenuBlueColor;
                }
            }
        }

        &.active a::after {
            //content: ' ';
            position: absolute;
            background-color: $AdminMenuBlueColor;
            width: 14px;
            height: 14px;
            top: 35px;
            right: -12px;
            border: solid 5px #fff;
            border-radius: 50%;
            z-index: 1;

            /*width: 12px;
            height: 12px;
            top: 17px;
            left: -6px;
            background-color: #1be2bc;*/
        }

        div {
            display: none;
            font-size: 12px;
            color: #fff;
        }
    }

    a {
        border: 1px solid transparent;
        border-radius: 5px;
        display: inline-flex;
        padding: 10px;
        //text-transform: capitalize;
        //opacity: .8;

        .menu-icon {
            width: 26px;
            height: 26px;
            //fill: #8e8ea6;
            //margin-bottom: 8px;
        }

        .badge {
            animation: opacity 2s infinite;
            background: #ff9900;
            border-radius: 50%;
            position: absolute;
            right: 24px;
            top: 14px;
            width: 5px;
            height: 5px;
        }
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.apps-popup {
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .15);
    font-size: 14px;
    height: auto;
    margin-top: 5px;
    position: fixed;
    right: 64px;
    transition: all .5s;
    width: 250px;
    z-index: 1000;

    @keyframes apps-popup-forward {
        0% {
            transform: scale(0);
            transform-origin: 20% 80%;
        }
        75% {
            transform: scale(1.1);
            transform-origin: 20% 80%;
        }
        100% {
            transform: scale(1);
            transform-origin: 20% 80%;
        }
    }

    @keyframes apps-popup-backward {
        0% {
            transform: scale(1);
            transform-origin: 20% 80%;
        }
        75% {
            transform: scale(1.1);
            transform-origin: 20% 80%;
        }
        100% {
            transform: scale(0);
            transform-origin: 20% 80%;
        }
    }

    &.user {
        top: 60px;
        right: 20px;
        margin: 0;
        padding: 15px;
        position: absolute;
        width: 235px;
        border-radius: 10px;
    }

    &.chat {
        border-radius: 5px;
        position: absolute;
        padding: 10px;
        left: 25px;
        bottom: 20px;
    }

    &.guest-popup {
        top: 45px;
        left: 0;
        position: absolute;
    }

    &.group-chat {
        left: 50px;
        top: 58px;
    }

    &.assign {
        border-radius: 5px;
        padding: 10px;
        position: absolute;
        right: 10px;
        left: auto;
        top: 40px;

        h4 {
            color: #231f20;
            text-align: center;
        }
    }

    &.reply {
        width: 330px;

        .search-input-chat {
            box-sizing: border-box;
            padding: 0 10px;
        }

        h4 {
            color: #231f20;
            text-align: center;
        }

        .popup-list-item {
            padding: 5px 10px 4px;
            border-radius: 5px;
            margin: 0 !important;

            &:hover {
                background: #dfebfd;
            }
        }
    }

    .popup-list {
        margin-top: 5px;
    }

    .popup-list-item {
        cursor: pointer;
        padding: 5px 10px 4px;
        border-radius: 3px;
        position: relative;
        font-size: 13px;
        color: #121416;

        &:hover {
            background: #dfebfd;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .user-status {
            height: 10px;
            width: 10px;
        }

        .avatar-block {
            margin-right: 7px;
        }

        .md-button {
            padding: 0 !important;

            &:hover {
                background: none !important;
            }
        }
    }

    h4 {
        font-weight: 500;
        padding-bottom: 6px;
        border-bottom: 1px solid #eeecec;
        margin-bottom: 5px;
    }

    .md-list-item-inner {
        overflow: hidden;
        padding-left: 0;
        white-space: nowrap;
        width: 90%;
    }

    .shortcut, .text-reply {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-reply {
        width: 65%;
    }

    .link-status {
        color: #0095cf;
        cursor: pointer;
    }

    .shortcut {
        padding-right: 4%;
        width: 30%;
        font-weight: 500;
    }

    .search-input-chat {
        margin-bottom: 10px !important;

        .md-input {
            border: 1px solid #eee;
            border-radius: 6px;
            background: rgba(0, 0, 0, .03);
            padding: 0 10px;
        }
    }

    h3 {
        cursor: default;
        font-size: 15px;
        margin: 0 0 5px;
        text-align: center;
    }

    md-menu-content {
        padding: 0;
    }
}

.ember-basic-dropdown-content {
    md-menu-content {
        max-height: 500px;
    }
}

