// Components
textarea::-webkit-input-placeholder {
    color: #bdbac2;
}

textarea::-moz-placeholder {
    color: #bdbac2;
}

textarea:-ms-input-placeholder {
    color: #bdbac2;
}

textarea:-moz-placeholder {
    color: #bdbac2;
}

input::-webkit-input-placeholder {
    color: #bdbac2;
}

input::-moz-placeholder {
    color: #bdbac2;
}

input:-ms-input-placeholder {
    color: #bdbac2;
}

input:-moz-placeholder {
    color: #bdbac2;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.link {
    color: #1844ef;
    cursor: pointer;

    &:hover {
        background-color: transparent !important;
        text-decoration: underline;
    }
}

.two-field {
    justify-content: space-between;

    > div {
        width: 45%;
    }
}

md-radio-button.md-checked {
    .md-off {
        border-color: #3f69cc !important;
    }

    .md-on {
        background-color: #3f69cc !important;
    }
}

md-checkbox.md-checked {
    [class^="icon-"], [class*=" icon-"] {
        background-color: #6198ff !important;
    }
}

md-checkbox.md-default-theme.md-checked.md-focused .md-container:before, md-checkbox.md-checked.md-focused .md-container:before {
    background-color: rgba(63, 105, 204, .3) !important;
}

.mini-avatar {
    border-radius: 100%;
    vertical-align: middle;
}

.svg-icon {
    height: 16px;
    width: 16px;
}

md-autocomplete {
    background: none !important;
}

.tag-input-wrapper {
    margin-top: 10px;
    position: relative;

    .add-tag {
        cursor: pointer;
        display: inline-block;
        padding: 4px 2px;

        .plus-icon {
            border-radius: 3px;
            margin: 0;
            height: 16px !important;
            width: 16px !important;
        }
    }

    .tag-item {
        background-color: #ddebff;
        border-radius: 5px;
        color: #2b78e4;
        cursor: pointer;
        display: inline-block;
        margin: 0 4px 5px 0;
        padding: 4px 8px;

        &.shopify {
            background-color: green !important;
            color: white !important;
            cursor: default;
        }
    }

    .tag-dropdown {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .1);
        box-shadow: 6px 6px 8px 0 rgb(0, 0, 0, .15);
        left: 0;
        max-height: 170px;
        overflow-x: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 10;

        div {
            cursor: pointer;
            padding: 10px 15px;

            &:hover {
                background-color: #f3f6f8;
            }
        }
    }
}

@media (max-width: 1400px) {
    .table {
        table.md-table .md-cell, table.md-table .md-column {
            padding: 0 5px !important;
        }
    }
}

@media (max-width: 1280px) {
    .table {
        table.md-table .md-cell, table.md-table .md-column {
            padding: 0 3px !important;
        }
    }
}
