$primary-color-grey: #989a9f;

.chat-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;

    *, ::after, ::before {
        box-sizing: border-box;
    }

    .emoji-btn {
        background-color: #eee;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        padding: 4px;
        margin: 0 3px;

        &:hover {
            background-color: #efefef;

            .svg-icon {
                fill: #2b78e4;
            }
        }
    }

    .icon-button {
        display: flex;

        &:hover {
            background: none !important;
        }
    }

    .divider {
        text-align: center;
        margin: 15px;
        position: relative;

        &:before {
            border-top: 1px solid #eeecec;
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            top: 12px;
        }

        span {
            font-size: 11px;
            color: #989a9f;
            display: inline-block;
            position: relative;
            background: #f9fdff;
            padding: 0 30px;
        }

        &._green {
            &:before {
                border-color: green;
            }

            span {
                color: green;
            }
        }

        &.new-messages {
            &:before {
                border-top-color: red;
            }

            span {
                color: red;
            }
        }
    }

    .arrow-icon {
        transition: .2s;
        transform: rotate(90deg);
        height: 16px;
        width: 16px;
    }

    .cp-Panel-toggle {
        color: #121416;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        padding: 10px 0;

        &.team-members,
        &.pinned-items {
            padding-bottom: 10px;
        }

        &.cp-is-open {
            .arrow-icon {
                transform: rotate(0deg);
            }

            .svg-icon {
                fill: #2b78e4;
            }

            .menu-link {
                font-weight: 700;
                color: #2b78e4;
            }
        }

        > .unread .menu-link {
            > span {
                font-weight: 700;
                color: #2b78e4;
            }

            > svg {
                fill: #2b78e4;
            }
        }
    }

    .actions-input {
        background-color: #fff;
        border-radius: 0 0 5px 5px;
        border: 1px solid #eeecec;
        border-top: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;

        &.focus {
            border-color: #b1b1b1;
        }

        > div > span {
            cursor: pointer;
            display: inline-block;
            margin: 0 5px;

            &.active, &:hover {
                .svg-icon {
                    fill: #2b78e4;
                }
            }
        }

        .md-button {
            padding: 0;
            min-width: auto;
            width: auto;
        }

        .picker-popup-wrapper {
            left: 10px;
            bottom: 55px;
            top: auto;
        }

        .emoji-btn {
            background-color: transparent;
        }

        .svg-icon {
            height: 18px;
            width: 18px;
        }
    }

    .chat-input {
        padding: 10px;
        position: relative;

        .form-send-button {
            background-color: #2b78e4;
            border-radius: 50%;
            display: flex;
            padding: 8px;
            transition: .25s;

            .svg-icon {
                fill: #fff;
                transition: .25s;
                height: 14px;
                width: 14px;
            }

            &:hover {
                background-color: #1E20A8;

                .svg-icon {
                    fill: #fff;
                }
            }

            &[disabled] {
                background-color: transparent;
                pointer-events: none;

                .svg-icon {
                    fill: #989a9f;
                }
            }
        }

        .application-loading-wrapper {
            margin-top: 0;

            .loader > div {
                height: 12px;
                width: 12px;
            }
        }

        .wrapper-icon-chat {
            left: 14px;
            bottom: 100px;
            right: auto;
            top: auto;
        }

        .popup-open {
            z-index: 3;

            .apps-popup {
                display: block;
            }
        }

        .action-field {
            display: flex;
            margin: 5px 0;
        }

        .kb-search-result {
            max-height: 235px;
            overflow: auto;

            .loadingText {
                padding: 10px;
            }

            .search-item {
                background-color: #fff;
                box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
                border: solid 1px #9e9e9e;
                border-radius: 4px;
                margin-bottom: 5px;
                padding: 2px 10px 10px;

                &:hover, &.selected {
                    border-color: #2B78E4;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .top-line {
                    color: $TitleColor;
                    display: flex;
                    justify-content: space-between;

                    .action-buttons {
                        max-width: 85px;

                        .svg-icon {
                            height: 20px;
                            width: 20px;
                        }
                    }

                    .alignCenter {
                        flex: 1;

                        div {
                            margin-right: 10px;
                        }
                    }

                    .md-button {
                        margin: 0;
                    }
                }

                .title {
                    font-weight: 500;

                    .mark {
                        color: orange;
                    }
                }

                .description {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 90%;
                }

                .status {
                    color: green;

                    &.DRAFT {
                        color: grey;
                        opacity: .8;
                    }
                }
            }
        }

        .kb-search {
            display: flex;
            flex-direction: row;
            margin: 5px 0;

            .kb-select {
                margin-right: 10px;
                width: 50%;

                .ember-power-select-selected-item {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .svg-icon-wrap {
                cursor: pointer;
                position: absolute;
                left: 12px;
                top: 50%;
                margin-top: -8px;
            }

            .md-input {
                padding-left: 35px;
                height: 100%;
            }
        }

        .reply-tab {
            color: #606a73;
            font-weight: 500;
            white-space: nowrap;

            span {
                cursor: pointer;
                display: inline-block;
                margin-right: 14px;
                padding: 0 10px 3px;

                &.disabled {
                    pointer-events: none;
                    opacity: .3;
                }

                &.active {
                    color: #2B78E4;
                    pointer-events: none;
                    border-bottom: 2px solid;
                }

                &.internal {
                    color: #c69608;
                }
            }
        }

        md-input-container {
            padding: 0;
            display: block;
        }

        textarea {
            overflow: scroll;
            padding: 14px 127px 0 50px;
        }

        textarea, .txt-input {
            background: #fff;
            border: 1px solid #eeecec;
            border-radius: 5px 5px 0 0;
            min-height: 50px;
            cursor: auto;

            &.focus {
                border-color: #b1b1b1;
            }

            &.isReply, &.isEditor {
                border-top: none;
                border-radius: 0;
            }
        }

        .action-text-editor {
            background-color: #f3f3f3;
            border-radius: 5px 5px 0 0;
            border: 1px solid #eeecec;
            border-bottom: none;
            padding: 5px;

            & + .txt-input {
                border-radius: 0;
            }

            &.focus {
                border-color: #b1b1b1;
            }

            svg {
                height: 16px;
                width: 16px;
                margin-top: 5px;
            }

            .md-button {
                border-radius: 5px;
                cursor: pointer;
                height: 30px;
                margin: 0 5px;
                line-height: normal;
                min-width: auto;
                width: 30px;

                &.md-focused {
                    background-color: transparent;
                }

                &.active, &:hover {
                    background-color: #f0f0f0 !important;
                    color: #2b78e4 !important;
                }
            }
        }

        .wrap-input {
            position: relative;
            display: flex;
            flex-direction: column;

            &.reply {
                .txt-input, .actions-input {
                    background: #fff5f5;
                }
            }

            &.isLoader {
                opacity: .3;
            }

            &.internal {
                .txt-input {
                    padding-left: 10px;
                }
            }

            .wrap-reply {
                background-color: #fff;
                border: 1px solid #eeecec;
                border-bottom: none;
                border-radius: 5px 5px 0 0;

                &.focus {
                    border-color: #b1b1b1;
                }

                &.isEditor {
                    border-radius: 0;
                }

                .replyTo {
                    background-color: #eee;
                    margin: 5px;

                    &:after {
                        background-color: #999;
                    }
                }
            }

            .txt-input {
                padding: 5px 10px;
                outline: none;
                overflow: auto;
                max-height: 100px;
                border-bottom: none;

                div, span, p {
                    display: inline-block;
                }

                ul, ol {
                    list-style: auto;
                    padding-left: 20px;
                }

                ol {
                    list-style: disc;
                }

                .emoji {
                    margin: 0 0 0 5px;
                    background-image: url('https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png');
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-size: 6100% 6100%;
                    vertical-align: text-bottom;
                }

                .user-group {
                    background-color: #f2c4b2;
                    border-radius: 5px;
                    color: #989a9f;
                    pointer-events: none;
                    outline: none;
                    padding: 2px 4px;
                }
            }

            .txt-input[placeholder]:empty::before {
                content: attr(placeholder);
                color: #989a9f;
            }

            .txt-input[placeholder]:empty:focus::before {
                content: " ";
            }
        }

        .calendar-icon {
            display: none;
        }
    }

    .chat-calendar {
        visibility: hidden;
        display: none;
    }

    .wrap-user-check {
        max-height: 500px;
        overflow-y: auto;
        padding-top: 0;
    }
}

.tabs-message {
    width: 320px;
    overflow-x: hidden;

    .wrap-search-block {
        padding: 11px 9px;
        margin-bottom: 4px;

        .search-block {
            align-items: center;
            display: flex;
            justify-content: space-between;
            position: relative;
        }

        .search-icon {
            height: 20px;
            position: absolute;
            width: 20px;
            z-index: 1;
            left: 7px;
        }

        .options-icon {
            cursor: pointer;
            height: 24px;
            width: 30px;
        }

        .search-input-chat {
            padding: 0;

            .md-input {
                padding: 0 5px 0 27px;
            }
        }
    }

    .search-result {
        color: #989a9f;
        padding: 10px;

        h4 {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .search-result-item {
            background: #fafbfd;
            border-radius: 5px;
            border: 1px solid #e8f2ff;
            font-size: 11px;
            font-weight: 400;
            padding: 5px;
            margin-bottom: 10px;

            p {
                margin: 5px 0 0;
            }
        }
    }

    .bookmark-tab {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        color: $TextGreyColor;
        padding: 5px 13px;
        margin-bottom: 4px;

        &.selected {
            background: #effaff;
            //color: #fff;

            .message-text {
                color: #ddd !important;
            }
        }

        .svg-icon {
            margin-right: 6px;
            height: 20px;
            width: 20px;
        }

        .bookmark-icon {
            fill: #d62516;
            margin-top: 6px;
        }
    }

    .message-icon {
        height: 20px;
        width: 20px;
    }

    .direct-icon {
        fill: green;
    }

    .thread-item {
        border-top: 1px solid #eeecec;
        border-left: none;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        padding: 5px 14px 7px;
        cursor: pointer;
        width: 100%;

        &.selected {
            //background-color: $AdminMenuBlueColor;
            //color: #fff;
            background-color: #effaff;
            color: #121416;
            border-left: 2px solid #2B78E4;

            .top_txt .guest-name {
                //color: #fff;
                //font-weight: 700;
            }

            .message-text {
                //color: #ddd !important;
                //color: #121416 !important;
            }
        }

        &:last-child {
            border-bottom: 1px solid #eeecec;
        }

        .thread-content {
            flex: 1;
            flex-direction: column;
            display: flex;
            overflow: hidden;
        }
    }

    .more-btn {
        text-align: center;
        margin: 15px 0;
        position: relative;

        &:before {
            border-top: 1px solid #eeecec;
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            top: 12px;
        }

        .svg-icon {
            margin-left: 8px;
            height: 20px;
            width: 20px;
            transform: rotate(90deg);
        }

        .txt-btn {
            background: #fff;
            border: 1px solid $AdminMenuBlueColor;
            border-radius: 10px;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 15px;
            position: relative;
            z-index: 1;
        }
    }

    .item-user-chat {
        cursor: pointer;
        padding: 5px 30px 5px 34px;
        display: flex;
        font-weight: 500;
        align-items: center;
        position: relative;

        &.selected {
            background: #effaff;
            //color: #fff !important;

            .message-text {
                color: #ddd !important;
            }
        }

        &.unread {
            color: $AdminMenuBlueColor;
            font-weight: 700;
        }

        &:hover {
            background-color: #effaff; //#cfe0ff
            //color: #fff;

            .chat-entry-icon, .mentioned-icon {
                //fill: #fff;
            }
        }

        img {
            height: 24px;
            width: 24px;
        }

        .title {
            flex: 1;
            display: flex;
            overflow: hidden;
        }

        .room-thread-action {
            align-items: center;
            display: flex;
            position: absolute;
            right: 5px;
        }

        .chat-entry-icon, .mentioned-icon {
            height: 20px;
            width: 20px;
            fill: #54a702;
        }

        .mentioned-icon {
            fill: $AdminMenuBlueColor;
            margin-left: 5px;
        }

        .lock-icon, .unlock-icon {
            height: 18px;
            margin-right: 5px;
            width: 18px;
        }

        .icon-status-user {
            background: #656a72;
            border-radius: 50%;
            border: 2px solid #fff;
            display: inline-block;
            height: 10px;
            margin: 18px 8px 0 -7px;
            width: 10px;
            z-index: 1;

            &.online {
                background: #54a702;
            }
        }
    }

    .cp-Panel {
        .arrow-icon {
            margin-right: 6px;
            margin-top: 3px;
        }

        .internal-inner-panel {
            //padding: 0 10px 0 14px;
            padding: 0;
        }

        .menu-link {
            align-items: center;
            display: flex;
            font-size: 14px;
            margin-right: 7px;

            .svg-icon {
                margin-right: 6px;
                height: 20px;
                width: 20px;
            }
        }

        .cp-Panel-toggle {
            padding: 0;
            align-items: center;

            > .alignCenter {
                padding: 10px 12px;
            }

            .md-button {
                height: 26px;
                margin: 4px 5px 0 0;
                padding: 0;
                width: 26px;
            }
        }

        .plus-circle-icon {
            height: 20px;
            width: 20px;
        }

        .top_txt {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .guest-name {
                color: #121416;
                font-weight: 500;
            }

            .message-time {
                font-size: 11px;
            }
        }

        .property-text {
            font-size: 11px;
            font-weight: 500;
            margin-bottom: 7px;
        }

        .bottom_txt {
            justify-content: space-between;
            display: flex;
            font-size: 12px;

            .message-text {
                color: #5d5e64;
                font-size: 13px !important;
                //font-weight: 500;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 5px;

                & * {
                    font-size: 14px !important;
                    display: inline-block;
                }

                .user-group {
                    background: #f2c4b2;
                    border-radius: 5px;
                    color: #989a9f;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 2px 5px;
                }
            }
        }
    }
}

.lock-icon {
    fill: #d62516;
}

.unlock-icon {
    fill: #54a702;
}

.chat-wrapper .expand-icon {
    background: #fff;
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: 70px;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #989a9f;
    //box-shadow: 0 0 1px 2px rgba(0, 0, 0, .15);
    opacity: .7;
    transition: .2s;
    //transform: translateX(0);
    z-index: 100;

    &:hover {
        opacity: 1;
        transform: translateX(-5px);
    }

    &.collapse {
        .svg-icon {
            transition: .2s;
            transform: rotate(0);
        }
    }

    .svg-icon {
        transition: .2s;
        transform: rotate(180deg);
        height: 20px;
        width: 20px;
    }
}

.visitor-info, .team-info, .user-info {
    //background-color: #f9fdff;
    width: 340px;
    padding: 5px;

    &.messages {
        padding: 0;
        display: flex;
        flex-direction: column;
        max-width: 25%;

        .divider {
            margin: 0 0 5px 0;
        }
    }

    &.column {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .kb-name {
            align-items: center;
            justify-content: space-between;
            background-color: #eeecec;
            color: $TitleColor;
            display: flex;
            padding: 5px 10px;
            font-size: 20px;
            font-weight: 500;
            margin: -10px -10px 10px;

            .svg-icon {
                height: 22px;
                width: 22px;
            }

            .md-button {
                margin: 0 -10px 0 0;
            }
        }

        .title {
            margin-bottom: 10px;
            font-size: 18px;
            color: $TitleColor;
        }

        .author {
            align-items: center;
            display: flex;
            margin-bottom: 10px;
            font-size: 12px;

            .md-avatar {
                margin: 0 10px 0 0;
            }
        }
    }

    .send-link {
        margin: 10px 0;

        .primary-button {
            width: 100%;
        }
    }

    .ql-container {
        border: none;

        .ql-editor {
            padding: 0;
            white-space: normal;
        }
    }

    .action-buttons {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .btn-link {
            height: 44px;
            margin-right: 10px;
            flex: 1;
            border: 2px solid #dadada;
            font-size: 12px;
            font-weight: 500;
            border-radius: 5px;
            line-height: 40px;
            letter-spacing: 0.7px;
            text-align: center;

            &:hover {
                border-color: #4a96ff;
                background-color: #fff;

                a {
                    color: #4a96ff;
                }
            }

            a {
                color: #adadad;
            }
        }
    }

    .user-avatar {
        text-align: center;
        margin-bottom: 10px;

        p {
            font-size: 18px;
            font-weight: 500;
            margin: 5px 0 14px;
        }
    }

    .market-user {
        margin: 0 3px 0 15px;
    }

    .user-name {
        color: #121416;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .lock-icon, .unlock-icon {
        height: 18px;
        margin-right: 5px;
        width: 18px;
    }

    .add-member {
        display: flex;
        justify-content: flex-end;

        .md-button {
            margin: 0;
            padding: 0;
            width: auto;
        }

        span {
            color: #2b78e4;
            font-size: 12px;
            font-weight: 700;
        }
    }

    .plus-icon {
        height: 20px;
        margin-right: 2px;
        vertical-align: middle;
        width: 20px;
    }

    .created-date {
        font-size: 14px;
        margin: 0 0 15px 25px;
    }

    .title-name {
        font-weight: 500;
        color: #121416;
        display: flex;
        margin-bottom: 10px;

        .svg-icon {
            margin-right: 5px;
            height: 24px;
            width: 24px;
        }
    }

    .icon-status-user {
        vertical-align: text-top;
    }

    .delete-user {
        cursor: pointer;
        display: none;
        position: absolute;
        right: 0;
        top: 0;
    }

    .list-member {
        .item-member {
            margin-bottom: 10px;
            position: relative;
            display: flex;
            align-items: center;

            &:hover {
                .delete-user {
                    display: block;
                }
            }

            .name {
                font-size: 14px;
                color: #121416;
            }

            .md-avatar {
                margin: 0 8px 0 0;
            }
        }
    }

    .info-item {
        background-color: #fff;
        border: 2px solid #f5f5f5;
        border-radius: 3px;
        padding: 5px 10px;
        margin-bottom: 10px;

        &.opportunities {
            .form-group {
                background-color: #effaff;
                border-radius: 3px;
                color: #121416;
                font-weight: 500;
                padding: 5px;
            }
        }

        .liquid-container, .liquid-child {
            overflow: visible;
        }

        .title-icon {
            margin-right: 7px;
            border-color: #ff55ff;
            height: 20px;
            width: 20px;
        }

        .title-info {
            color: #121416;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .title {
            display: flex;
            flex: 1;
            overflow: hidden;
        }

        .status {
            //background-color: #e4f2fd;
            color: #90caf9;
            display: inline-block;
            font-weight: 500;
            font-size: 12px;
            margin-top: 3px;
            padding: 3px 10px 3px 0;

            &._green {
                //background-color: #e8f9f5;
                color: green;
            }
        }

        .price {
            color: #90caf9;
            font-weight: 500;

            &._green {
                color: green;
            }
        }

        .profile-icon {
            fill: #989a9f !important;
            margin-right: 10px;
            height: 24px;
            width: 24px;
        }

        .cp-Panel-toggle {
            align-items: center;
        }

        .primary-button {
            width: 100%;
        }

        .description {
            margin-left: 25px;
        }

        .count-form {
            margin-right: 10px;
        }

        form md-input-container {
            flex: 1;
            width: auto;
        }

        .progress-form {
            margin-bottom: 20px;
            position: relative;

            .bar {
                background: #54a702;
                border-radius: 2px;
                height: 8px;
                opacity: .3;
            }

            .line {
                background: #54a702;
                border-radius: 2px;
                position: absolute;
                left: 0;
                top: 0;
                height: 8px;
                width: 0;
            }
        }

        > .svg-icon {
            margin-right: 4px;
            vertical-align: middle;
        }

        .profile-item {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .svg-icon {
                margin-right: 7px;
                height: 20px;
                width: 20px;
            }

            .value {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .alignCenter {
                flex-wrap: wrap;
            }
        }

        span {
            .svg-icon {
                vertical-align: bottom;
            }

            &.shopify {
                color: #5E8E3E;
            }

            &.bigcommerce {
                color: #32323B;
            }

            &.username {
                flex: 1;
                color: $LinkColor;
            }
        }

        md-tabs {
            width: 100%;
        }
    }

    .exchanges-section {
        min-height: 332px;

        .liquid-container {
            padding: 15px 0;
        }

        .img-item {
            width: 31%;
            margin-right: 3%;
            height: 100px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            &:nth-child(3n + 0) {
                margin-right: 0;
            }
        }

        .doc-item {
            display: flex;
            margin-bottom: 10px;

            .doc-item-icon {
                margin-right: 5px;
            }

            .svg-icon {
                height: 20px;
                width: 20px;
            }
        }

        .links-tab {
            .link {
                display: inline-block;
                margin-bottom: 10px;
            }
        }
    }

    label {
        //font-size: 12px;
        font-weight: 500 !important;
        margin-right: 5px;
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .visitor-info-wrap {
        padding: 13px 15px;
    }

    .visitor-info-header {
        border-bottom: 2px solid #fbfbfb;
        color: #606a73;
        font-size: 18px;
        margin: 0;
        padding: 0 0 12px;

        .info {
            vertical-align: middle;
        }
    }

    .visitor-avatar {
        color: #121416;
        text-align: center;
        font-weight: 500;
        margin-bottom: 12px;

        .braincheck-avatar {
            width: 28px;
            margin: auto;
        }

        .alignCenter {
            justify-content: center;
        }

        .svg-icon {
            margin-right: 8px;
            height: 28px;
            width: 28px;
        }
    }

    .cp-Panel-body-inner {
        padding-top: 10px;
    }
}

.pinned-items {
    border: solid 2px #eff0f0;
    border-radius: 8px;
    display: flex;
    margin-bottom: 5px;
    padding: 10px;
    position: relative;

    .md-avatar {
        margin-right: 10px;
    }

    .content {
        overflow: hidden;
    }

    .unpin-button {
        margin: 0;
        padding: 0;
        position: absolute;
        right: 2px;
        top: 2px;
        width: 24px;
        height: 24px;

        .close-icon {
            height: 16px;
            vertical-align: middle;
            width: 16px;
        }
    }

    .top_content {
        span {
            margin-right: 7px;
        }
    }

    .inner-text {
        font-weight: 500;
        margin-top: 7px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    img {
        max-width: 100%;
        max-height: 80px;
    }
}

.wrap-avatar {
    position: relative;
    margin-right: 10px;
    display: flex;
    align-items: center;

    .md-avatar {
        //border: 1px solid #2B78E4;
        margin: 0 !important;
    }

    .user-status {
        bottom: 0;
        height: 8px;
        width: 8px;
    }

    .wrap-svg {
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        left: 23px;
        top: 21px;
        height: 18px;
        width: 18px;
    }
}

.participants-avatars {
    color: #5d5e64;
    font-size: 14px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 5px;
    border-radius: 5px;
    border: 1px solid rgb(152 154 159 / 40%);

    .user-avatar {
        box-shadow: 0 0 0 1px #fff;
        margin-right: -5px !important;
    }

    .count {
        height: 25px;
        padding: 0 7px;
        background: #2B78E4;
        text-align: center;
        color: #fff;
        box-shadow: 0 0 0 1px #fff;
        line-height: 22px;
        border-radius: 25px 7px 7px 25px;
        margin-right: -5px;
        opacity: 0.8;
    }
}

.message-section {
    background-color: #f9fdff;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(218, 218, 218, .5);
    border-left: 1px solid rgba(218, 218, 218, .5);

    .title-name {
        font-weight: 500;
        font-size: 16px;
        color: #231f20;
        display: flex;
        align-items: center;
        position: relative;

        .wrap-avatar .wrap-svg {
            height: 10px;
            width: 10px;
            left: 14px;
            top: 14px;
        }

        .md-avatar {
            margin-right: 8px;
        }
    }

    .bookmark-icon {
        margin-right: 8px;
    }

    .typing-text {
        display: none;
        font-size: 11px;
        margin: 0 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .lock-icon, .unlock-icon {
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    .replyTo {
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
        border-radius: 4px;
        color: #121416;
        font-weight: 400;
        padding: 5px 5px 5px 15px;
        position: relative;
        margin-bottom: 5px;

        * {
            font-size: 12px !important;
        }

        &:after {
            background-color: #eee;
            content: "";
            height: calc(100% - 10px);
            position: absolute;
            top: 5px;
            left: 5px;
            width: 2px;
        }

        .close-icon {
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 5px;

            .svg-icon {
                height: 20px;
                width: 20px;
            }
        }

        .reply-info {
            margin-bottom: 5px;
        }

        .author {
            margin-right: 10px;
        }

        .date {
            color: #989a9f;
        }
    }

    .message-section-title {
        border-bottom: 1px solid rgba(218, 218, 218, .5);
        justify-content: space-between;
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 10px;
        position: relative;

        .md-icon-button {
            margin: 0 0 0 15px;
            padding: 0;
            height: 24px;
            width: 24px;
        }

        .svg-icon {
            height: 24px;
            width: 24px;

            &.opened {
                fill: $AdminMenuBlueColor;
            }

            &:hover {
                fill: #2b78e4;
            }
        }

        .whosread {
            > span {
                margin-right: 5px;
            }

            span {
                line-height: 25px;
                vertical-align: top;
            }

            .all-users {
                position: relative;
                display: inline-block;
                min-width: 25px;
                height: 25px;
                border-radius: 12px;
                vertical-align: top;
                background-color: #d0d0d0;
                cursor: pointer;
                text-align: center;

                span {
                    display: inline-block;
                    padding: 5px;
                }

                .user-list {
                    position: absolute;
                    width: 200px;
                    top: 25px;
                    left: -90px;
                    max-height: 600px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    background-color: #fff;
                    border: solid 1px #e7e7e7;
                    z-index: 10;
                    cursor: default;
                    text-align: left;

                    .item {
                        margin: 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;

                        .md-avatar {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.messages {
    flex: 1;

    .loader > div {
        height: 12px;
        width: 12px;
    }

    .bookmark-banner {
        margin: 10px;
        padding: 10px;
        border: solid 1px #ececec;
        border-radius: 10px;
        background-color: #DFEBFD;
        color: $TextBlackColor;
        display: flex;

        b {
            display: block;
            margin-bottom: 10px;
        }

        .svg-icon {
            margin-right: 10px;
            height: 20px;
            width: 20px;
        }
    }

    .bookmark {
        margin: 10px;
        padding: 10px 20px 0 20px;
        border: solid 1px #2b78e4;
        border-radius: 10px;
        background-color: #fff;

        &:hover {
            background-color: #effaff;
        }

        .smile-icon {
            fill: #5d5e64;
        }

        .link-to-msg {
            cursor: pointer;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }

        .lock-icon,
        .unlock-icon {
            width: 16px;
            margin: 0;
            height: 16px;
            vertical-align: bottom;
        }
    }

    .reminder {
        background-color: $InputBorderColor;

        .svg-icon {
            margin: 0;
            vertical-align: bottom;
        }
    }

    .scrollable-content {
        height: 100%;
    }

    &.hidden {
        .tse-scroll-content {
            overflow: hidden;
        }
    }

    .wrap-title-date {
        text-align: center;
        margin: 15px 0;
        position: relative;

        &:before {
            border-top: 1px solid #eeecec;
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            top: 12px;
        }
    }

    .title-date {
        background: #eee;
        border-radius: 10px;
        color: #989a9f;
        display: inline-block;
        font-size: 11px;
        padding: 5px 30px;
        position: relative;
        z-index: 1;
    }

    .user-group {
        color: #2b78e4;
        outline: none;
    }

    .msg {
        height: auto;
        min-height: auto;
        padding: 10px 15px;
        position: relative;

        &:hover {
            background: #effaff;

            .reply-icon-message {
                display: block;
            }
        }

        &.pinned {
            background-color: #FDF9E9;
        }

        &.bookmarked {
            background-color: #FFF3F4;
        }

        &.pinned.bookmarked {
            background-color: #C8E6C9;
        }

        &.internal-note {
            background: #fdf9e9;
        }

        .smile-plus-icon {
            height: 20px;
            margin-left: 10px;
            width: 20px;
        }

        .braincheck-title {
            align-items: center;
            color: #121416;
            display: flex;
            margin-bottom: 5px;

            .svg-icon {
                height: 24px;
                width: 24px;
            }

            .name {
                font-weight: 500;
                margin: 0 10px;
            }

            .date {
                font-size: 10px;
                margin-top: 3px;
            }
        }

        .braincheck-content {
            padding: 0 10px 0 50px;
            position: relative;

            &:before {
                background-color: orange;
                border-radius: 10px;
                content: '';
                height: 100%;
                left: 35px;
                top: 0;
                position: absolute;
                width: 3px;
            }

            .inner-content {
                display: flex;

                img {
                    width: 16px;
                    height: 16px;
                    margin: 10px 10px 0 3px;
                }

                .text {
                    margin: auto;
                }
            }

            .user {
                color: #121416;
                font-weight: 500;
                gap: 10px;
            }

            .tags {
                margin-top: 5px;

                .tag {
                    border: 1px solid #8b8b8b;
                    color: green;
                    display: inline-block;
                    font-size: 12px;
                    border-radius: 3px;
                    padding: 2px 5px;
                    text-transform: uppercase;

                    &.closed {
                        //background-color: orange;
                        color: red;
                    }
                }
            }
        }

        .top-info-msg {
            font-size: 10px;

            .name {
                color: #121416;
                font-size: 15px;
                font-weight: 500;
                margin-right: 10px;
            }

            .date {
                color: #34a853;
            }

            .read-msg, .edited-msg {
                color: #34a853;

                &:before {
                    content: '';
                    width: 2px;
                    margin: 0 6px 0 4px;
                    border-radius: 50%;
                    vertical-align: middle;
                    background: $primary-color-grey;
                    display: inline-block;
                    height: 2px;
                }
            }

            .edited-msg {
                color: #989a9f;
            }
        }

        .content {
            .status-send {
                position: relative;
                margin: 11px 0 0 18px;

                &.active {
                    md-icon {
                        color: #007bff;
                    }
                }

                md-icon {
                    display: block;
                }
            }
        }

        .inner-text {
            color: #5d5e64;
            font-size: 13px;
            margin: 0;
            overflow-wrap: anywhere;
            line-height: 1.5;

            &.article {
                align-items: center;
                display: flex;

                .svg-icon {
                    fill: #121416;
                    margin-top: 7px;
                }
            }

            a {
                color: #2B78E4;

                &:hover {
                    text-decoration: underline;
                }
            }

            .link-article {
                a {
                    color: #2B78E4;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .description {
                    font-size: 13px;
                    font-weight: 400;
                }
            }

            .message-deleted {
                background: #d4d3d5;
                border: 1px solid #eee;
                border-radius: 4px;
                color: $TextLightGreyColor;
                display: inline-block;
                font-size: 13px;
                font-weight: 500;
                padding: 9px 10px;
            }

            .user-group {
                background: #f2c4b2;
                border-radius: 5px;
                color: #989a9f;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 5px;
            }

            > a {
                background: #2B78E4;
                padding: 7px 7px;
                cursor: pointer;
                border-radius: 4px;
                font-size: 12px;
                margin-right: 10px;
                color: #fff;
                display: inline-flex;
            }

            .svg-icon {
                margin-right: 5px;
                height: 20px;
                width: 20px;
            }

            .product-list {
                .preview {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-bottom: 10px;

                    .product-image {
                        display: block;
                        width: 80px;
                        height: 80px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        vertical-align: middle;
                    }

                    .info {
                        flex: 10;
                        padding-left: 10px;

                        .title {
                            font-weight: normal;
                            margin-bottom: 10px;
                        }

                        .price {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .emojis {
            align-items: center;
            display: inline-flex;
            margin: 5px 0;
            width: 90%;
            flex-wrap: wrap;

            .emoji-wrap {
                align-items: center;
                background-color: #eee;
                //border: 2px solid #2b78e4;
                border-radius: 5px;
                margin: 0 4px 2px 0;
                position: relative;
                cursor: pointer;
                display: flex;
                padding: 4px 6px 4px 4px;
                //color: #2b78e4;

                & + .md-icon-button {
                    margin: 0 0 0 7px;
                    padding: 0;
                    height: auto;
                    width: auto;
                    line-height: normal;
                }
            }

            .emoji-count {
                font-size: 14px;
                font-weight: 500;
                margin-left: 2px;
            }

            .emoji-author-field {
                padding: 5px;
                bottom: 30px;
                border-radius: 6px;
                background-color: #fff;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
                position: absolute;
                z-index: 1000000;
                margin-top: -60px;
                text-align: left;

                > div {
                    white-space: nowrap;
                }
            }
        }

        .text {
            font-weight: 500;
            margin-top: 5px;

            .title-note {
                color: #2b78e4;
                cursor: pointer;
                font-size: 13px;
                font-weight: 500;
                text-decoration: underline;
                margin: 0 0 5px;
                display: none;
            }

            .reply {
                cursor: pointer;
                position: absolute;
                right: 21px;
                bottom: 1px;
                color: #2161a5;
                font-size: 13px;
                font-weight: 500;
                display: none;
            }

            &.note {
                .user-name, .reply {
                    display: inline-block;
                    color: #94A4AF;
                }

                .title-note {
                    display: block;
                }
            }

            .user-name {
                background: #f2c4b2;
                border-radius: 5px;
                display: none;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 5px;
            }

            .image-message {
                align-items: center;
                color: #2b78e4;
                font-weight: 500;
                display: inline-flex;
                max-width: 300px;

                &.file {
                    background-color: #edf1f6;
                    padding: 5px 10px;
                }

                .svg-icon {
                    fill: #2b78e4;
                    margin-right: 8px;
                    height: 24px;
                    width: 24px;
                }

                .icon-upload {
                    margin: 0 0 0 10px;
                }

                .size {
                    color: #989a9f;
                    font-size: 12px;
                    font-weight: 400;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .star-icon-message, .reply-icon-message {
            display: none;
            margin: 0;
            position: absolute;
            right: 20px;
            top: 0;

            .reply-icon {
                height: 24px;
                width: 24px;
            }
        }

        md-icon {
            &.active {
                color: #ffbb3e;
                display: block;
            }
        }

        .smile-icon {
            height: 22px;
            width: 22px;
        }

        .pin {
            display: inline-block;
            font-size: 11px;
            margin-left: 10px;
            color: #fbbc05;
        }

        .office-pin-icon {
            fill: #fbbc05;
            height: 12px;
            margin-right: 2px;
            vertical-align: middle;
            width: 11px;
        }

        .message {
            display: flex;

            &.unread {
                .inner-text {
                    color: #2B78E4;
                }
            }

            .wrap-avatar {
                align-items: initial;
            }

            .reply-in-thread-info {
                border-radius: 4px;
                cursor: pointer;
                padding: 5px;
                display: inline-block;
                border: solid 1px #2b78e4;
                background-color: #edf1f6;
                color: #2b78e4;
                font-size: 12px;

                &:hover {
                    opacity: .8;
                }
            }

            .reactions {
                align-items: center;
                background-color: #fff;
                //box-shadow: 0 0 1px 2px rgba(0, 0, 0, .15);
                border-radius: 5px;
                border: 1px solid #acacae;
                display: none;
                top: 10px;
                padding: 0 10px;
                position: absolute;
                right: 25px;

                .svg-icon {
                    height: 22px;
                    width: 22px;
                }

                .emoji-btn {
                    background-color: transparent;
                }

                .pushpin-icon {
                    &.red {
                        fill: red;
                    }
                }

                .smile-icon {
                    fill: #5d5e64;
                }

                .md-button {
                    margin: 0;
                    padding: 0 5px;
                    line-height: normal;
                    display: inline-flex;
                    align-items: center;
                    height: auto;
                    width: auto;
                    border-radius: inherit;
                    vertical-align: inherit;
                }

                .md-menu, .emoji-btn {
                    cursor: pointer;
                    display: inline-flex;
                    margin: 0 3px;
                }
            }
        }

        &:hover {
            .reactions {
                display: flex;
            }
        }

        .date-message {
            text-align: right;
        }
    }
}

.chat-form {
    font-weight: 400;

    .title-create-room {
        line-height: 1.4;
        margin-bottom: 20px;
    }

    .switch-title {
        font-style: italic;
    }

    form {
        .label {
            margin-bottom: 5px;
        }

        .paper-switch {
            &.md-checked {
                .md-container:before {
                    content: "Public";
                    left: 8px;
                }

                .md-bar {
                    background: #54a702;
                }

                .md-thumb {
                    background: #fff;
                }
            }

            .md-thumb-container {
                width: 47px;
            }

            .md-container {
                margin-right: 15px;
                width: 70px;

                &:before {
                    content: "Private";
                    color: #fff;
                    font-size: 11px;
                    font-weight: 500;
                    position: absolute;
                    right: 8px;
                    top: -2px;
                    z-index: 10;
                }

                .md-bar {
                    width: 100%;
                }
            }
        }
    }

    .info-icon {
        height: 14px;
        width: 14px;
    }

    h4 {
        font-weight: normal;
        margin-bottom: 20px;
    }
}

.picker-popup-wrapper {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .3);
    background-color: #fff;
    border-radius: 5px;
    top: 0;
    padding-bottom: 5px;
    position: absolute;
    right: 85px;
    width: 280px;
    z-index: 1000;

    &.inline {
        right: auto;
        top: auto;
    }

    .emojis-title {
        border-bottom: 1px solid rgba(0, 0, 0, .2);
        padding: 5px;
        display: flex;
    }

    .wrap-icon {
        border-radius: 5px;
        cursor: pointer;
        display: inline-flex;
        padding: 3px;
        margin: 0 4px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &.active, &:hover {
            background-color: #dfebfd;
        }
    }

    .group-icon {
        height: 20px !important;
        width: 20px !important;
    }

    .emojis-list {
        height: 240px;
        display: flex;
        flex-flow: row wrap;
        padding: 5px 4px 5px 6px;
        overflow: auto;

        > div {
            background-image: url('https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png');
            cursor: pointer;
            width: 24px;
            height: 24px;
            margin: 0 5px 5px;
            background-size: 6100% 6100%;

            &:nth-child(8n + 1) {
                margin-left: 0;
            }

            &:nth-child(8n) {
                margin-right: 0;
            }

            /*image-rendering: optimizeQuality;
            height: 24px;
            display: inline-block;
            padding: 5px;
            text-indent: -9999em;
            width: 24px;
            transform: scale(1);*/

            &:hover {
                transform: scale(1.15);
            }
        }
    }
}

.ecommerce-info {

    .svg-icon {
        height: 18px;
        width: 18px;
    }

    .title {
        color: #121416;
        font-weight: 500;

        .svg-icon {
            margin-right: 5px;
        }
    }

    .tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #121416;
        padding: 10px 0;
        cursor: pointer;

        .alignCenter {
            border-bottom: 2px solid transparent;
            padding: 4px 2px;
            font-weight: 500;
        }

        .svg-icon {
            margin-right: 5px;
        }

        .active {
            border-color: #2B78E4;
        }
    }

    .link {
        &.back-to-orders {
            color: #2B78E4 !important;

            .svg-icon {
                margin-right: 5px;
            }
        }
    }

    .order-details {
        border-bottom: 1px solid #eeecec;
        font-size: 14px;
        margin-top: 20px;

        .primary-button {
            margin-left: 10px;
        }

        .cp-Panel {
            margin-top: 10px;
        }

        .cp-Panel-toggle {
            .alignCenter .svg-icon {
                margin-right: 5px;
            }

            &.cp-is-open {
                color: #508AEE;

                .svg-icon {
                    fill: #508AEE;
                }
            }
        }
    }

    .order-list {
        margin-top: 10px;
        max-height: 400px;
        overflow-y: auto;

        .order-item {
            border: 2px solid #eeecec;
            border-radius: 3px;
            cursor: pointer;
            font-size: 14px;
            padding: 10px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            .svg-icon {
                margin-right: 5px;
            }

            .total-price {
                color: #FBBC05;
                font-weight: 500;

                &.closed {
                    color: #34A853;
                }
            }
        }
    }

    .order-id {
        color: #121416;
        font-weight: 500;
    }

    .date {

    }

    .status-date {
        color: #121416;
        margin-bottom: 10px;
    }

    .data {
        color: #121416;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin-bottom: 5px;

        &.orders {
            border-bottom: solid 1px #eeecec;
            padding: 10px 2px;
            font-weight: 500;
            justify-content: space-between;

            .alignCenter {
                gap: 5px;
            }
        }

        .title {
            font-weight: 500;
        }

        .link {
            color: #2B78E4;
            font-size: 13px;
        }

        .refund_quantity {
            width: 80px;
            margin: 0;
        }

        .product-image {
            display: inline-block;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: middle;
            margin-right: 15px
        }
    }

    .deselect-products {
        height: 25px;
        line-height: 25px;
        text-align: right;
        cursor: pointer;

        &:hover {
            color: $AdminMenuBlueColor;
        }
    }

    .share-btn {
        button {
            margin: 0;
        }

        span {
            display: inline-block;
            height: 32px;
            line-height: 32px;
        }
    }

    .panel {
        padding: 10px;

        &.product-list {
            border-top: none;
            padding-top: 0;
            padding-bottom: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            max-width: 100%;
            max-height: 500px;
            overflow-y: auto;

            .product-image {
                display: block;
                width: 100%;
                height: 100px;
                background-repeat: no-repeat;
                background-size: cover;
                vertical-align: middle;
                border: solid 2px transparent;
            }

            .preview {
                width: 33%;
                padding: 0 5px;
                max-width: 33%;
                cursor: pointer;

                &.selected {
                    .product-image {
                        border-color: $AdminMenuBlueColor;
                    }
                }

                .info {
                    position: relative;
                    height: 60px;

                    .wrapper {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        background-color: $BodyColor;
                        z-index: 1;

                        .title {
                            width: 100%;
                            max-width: 100%;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }

                        .price {
                            font-weight: 600;
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }
                    }
                }

                &:hover {
                    .info {
                        .wrapper {
                            .title {
                                text-overflow: unset;
                                white-space: unset;
                            }
                        }
                    }
                }
            }
        }
    }

    .status-select {
        display: flex;
        border: solid 1px #eeecec;
        border-right: none;
        margin-bottom: 10px;
        border-radius: 3px;
        text-align: center;

        .item {
            cursor: pointer;
            flex: 1;
            padding: 10px 0;
            margin: -1px;
            border-right: solid 1px #eeecec;

            &:first-child {
                border-radius: 3px 0 0 3px;
            }

            &:last-child {
                border-radius: 0 3px 3px 0;
            }

            &.selected {
                cursor: default;
                background-color: #eeecec;
            }
        }
    }

    .order-tags {
        .tag-item {
            background: #ddebff;
            border-radius: 5px;
            color: #2b78e4;
            display: inline-block;
            font-size: 13px;
            font-weight: 400;
            margin: 0 4px 5px 0;
            padding: 4px 8px;

            &.closed {
                background-color: #34A85355;
                color: #34A853;
            }

            &.cancelled {
                background-color: #FBBC0555;
                color: #FBBC05;
            }
        }
    }
}

.cancel-order-sidenav,
.refund-order-sidenav {
    span {
        &.shopify {
            color: #5E8E3E;
        }

        &.bigcommerce {
            color: #32323B;
        }

        .platform-logo {
            height: 16px;
            width: 16px;
            vertical-align: text-bottom;
        }

        &.username {
            text-transform: none;
            text-align: right;
            font-size: 13px;
            margin-left: 20px;
            color: $LinkColor;
        }
    }

    .form-group {
        .secondary-2-button {
            margin-left: 10px;
        }

        .svg-icon {
            margin-right: 3px;
            height: 20px;
            width: 20px;
        }
    }
}

.braincheck-avatar {
    .svg-icon {
        width: 20px;
        height: 20px;
        margin-left: 3px;
    }
}

.star-switch {
    h4 {
        color: #212529;
        font-size: 18px;
        margin: 22px 0 5px;
        padding: 0 9px;
    }

    .close-button {
        margin: 0 !important;
        position: absolute;
        right: 20px;
        top: 14px;
    }

    .star-date {
        position: absolute;
        left: 35px;
        top: 14px;
        margin: 0;
    }

    .list-reply-message {
        padding: 10px;

        li {
            border-bottom: 2px solid #fbfbfb;
            color: #bdbac2;
            padding: 12px 0;
            position: relative;

            &:hover {
                border-color: #007bff;

                .jump, .star-icon-message {
                    display: block;
                }
            }
        }

        .star-text {
            margin: 5px 0 0;
        }

        .star-icon-message {
            position: absolute;
            right: -14px;
            display: none;
            top: 2px;

            md-icon {
                color: #ffbb3e;
            }
        }

        .jump {
            border: 1px solid;
            border-radius: 5px;
            cursor: pointer;
            display: none;
            padding: 0 5px 2px;
            position: absolute;
            right: 32px;
            top: 12px;

            &:hover {
                color: #ffbb3e;
            }
        }
    }
}

.list-guest {
    .guest-item {
        border: 1px solid #ffd088;
        border-radius: 4px;
        cursor: pointer;
        padding: 10px;
        position: relative;
        margin: 0 0 7px;
        transition: .3s;

        &:hover {
            border-color: #286efa;
            background-color: rgba(0, 0, 0, .3);

            .action-button {
                display: block;
                opacity: 1;
            }
        }
    }

    .action-button {
        border: 1px solid transparent;
        border-radius: 4px;
        bottom: 7px;
        color: #fff;
        display: none;
        opacity: 0;
        padding: 5px 10px;
        line-height: initial;
        margin: 0;
        min-width: auto;
        position: absolute;
        transition: .2s;
        z-index: 1;

        &:hover {
            border-color: #fff;
        }

        &.guest-link {
            right: 15px;
        }

        &.associate {
            left: 15px;
        }
    }

    .guest-title {
        display: inline-block;
        width: 30%;
    }
}

.popup-reply {
    width: 400px;

    .md-dialog-content {
        padding: 15px;
    }

    .reply-block {
        border-left: 3px solid rgba(0, 0, 0, .12);
        color: #c1bebe;
        padding: 0 10px 2px;
    }

    textarea {
        border: none !important;
    }
}
